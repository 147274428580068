import { computed, ComputedRef, Ref } from 'vue'
import { RouteLocationNormalizedGeneric, useRoute, useRouter } from 'vue-router'
import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'

import router from 'src/router'
import {
  CoverageStats,
  Drilldown,
  fetchContextNetwork,
  FetchedData,
  fetchWidgetData,
  getWidgetConfig,
  WorkbenchMode,
  fetchVerbatims,
} from './Workbench.utils'
import { AnyWidgetConfig, Dashboard, DashboardConfig, WidgetConfig, WidgetName } from 'src/types/DashboardTypes'
import { Site } from 'src/store/modules/app'
import { ChrysalisQueryType, QueryType, SavedQuery } from 'src/types/Query.types'
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { UserProfile } from 'src/types/UserTypes'
import { Model } from 'src/types/AnalysisTypes'
import { addValueToQueryParam, defaultConfig, ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import QueryUtils from 'src/utils/query'
import { SET_WIDGET_CONFIG } from 'src/store/types'

import {
  ThemeTreeWidget,
  AiOverviewSummary,
  AiSummary,
  SegmentationWidget,
  NpsTimeline,
  ScoreTimeline,
  SentimentTimeline,
  Timeline,
  ContextNetwork,
  VerbatimsWidget,
  SegmentCorrelation,
  PivotTable,
  QuadrantWidget,
  KeyPhrases,
  QueryDetails,
} from 'components/DataWidgets'

export type WidgetComponentMap = {
  [W in (typeof WidgetList)[number] as W['key']]: W['component']
}

export type WidgetInstance = InstanceType<WidgetComponentMap[WidgetKey]>

// A map of widget keys to their props e.g WidgetPropsMap["nps-timeline"]
export type WidgetPropsMap = {
  [K in keyof WidgetComponentMap]: InstanceType<WidgetComponentMap[K]>['$props']
}

export type WidgetKey = (typeof WidgetList)[number]['key']

type WidgetEvents = Record<string, (...args: any[]) => any>

export const WidgetIcons: Record<WidgetKey, string> = {
  /* eslint-disable @typescript-eslint/no-require-imports */
  'ai-overview-summary': require('assets/img/dashboards/dash-summary.svg') as string,
  'ai-summary': require('assets/img/dashboards/dash-summary.svg') as string,
  'themes-concepts': require('assets/img/dashboards/dash-queries.svg') as string,
  'nps-timeline': require('assets/img/dashboards/dash-satisfaction.svg') as string,
  'score-timeline': require('assets/img/dashboards/dash-score.svg') as string,
  'sentiment-timeline': require('assets/img/dashboards/dash-sentiment2.svg') as string,
  'verbatims': require('assets/img/dashboards/dash-verbatims.svg') as string,
  'segments': require('assets/img/dashboards/dash-segmentation-new.svg') as string,
  'timeline': require('assets/img/dashboards/dash-timeline.svg') as string,
  'context-network': require('assets/img/dashboards/dash-context-network.svg') as string,
  'segment-correlation': require('assets/img/dashboards/dash-correlation.svg') as string,
  'quadrant': require('assets/img/dashboards/dash-quadrant.svg') as string,
  'pivot-table': require('assets/img/dashboards/dash-pivot-table.svg') as string,
  'key-phrases': require('assets/img/dashboards/dash-keyphrases.svg') as string,
  'query-details': require('assets/img/dashboards/dash-query-details.svg') as string,
}

// All widgets that can be added to the workbench in any mode.
export const WidgetList = [
  {
    key: 'ai-overview-summary',
    label: 'Dataset Overview',
    component: AiOverviewSummary,
  },
  {
    key: 'ai-summary',
    label: 'At a Glance',
    component: AiSummary,
  },
  {
    key: 'themes-concepts',
    label: 'Themes',
    component: ThemeTreeWidget,
  },
  {
    key: 'nps-timeline',
    label: 'Net Promoter Score (NPS)',
    component: NpsTimeline,
  },
  {
    key: 'score-timeline',
    label: 'Score',
    component: ScoreTimeline,
  },
  {
    key: 'sentiment-timeline',
    label: 'Sentiment',
    component: SentimentTimeline,
  },
  {
    key: 'context-network',
    label: 'Context Network',
    component: ContextNetwork,
  },
  {
    key: 'verbatims',
    label: 'Verbatims',
    component: VerbatimsWidget,
  },
  {
    key: 'segments',
    label: 'Segmentation',
    component: SegmentationWidget,
  },
  {
    key: 'timeline',
    label: 'Timeline',
    component: Timeline,
  },
  {
    key: 'segment-correlation',
    label: 'Top Correlations',
    component: SegmentCorrelation,
  },
  {
    key: 'pivot-table',
    label: 'Pivot Table',
    component: PivotTable,
  },
  {
    key: 'quadrant',
    label: 'Quadrant Chart',
    component: QuadrantWidget,
  },
  {
    key: 'key-phrases',
    label: 'Key Phrases',
    component: KeyPhrases,
  },
  {
    key: 'query-details',
    label: 'Query Details',
    component: QueryDetails,
  },
] as const

// Get the list of widgets that are enabled for the given mode.
export const useWidgetList = (
  mode: ComputedRef<WorkbenchMode>,
  drilldownType: Ref<Drilldown>,
  config: Ref<DashboardConfig['widgets']>,
) => {
  const store = useStore()

  const site = computed(() => store.getters.currentSite as Site)
  const hasNumericFields = computed(() => store.getters.hasNumericFields as boolean)
  const hasSentiment = computed(() => store.getters.hasSentiment as boolean)
  const hasNPS = computed(() => store.getters.hasNPS as boolean)
  const hasScoreFields = computed(() => store.getters.hasScoreFields as boolean)
  const hasDate = computed(() => store.getters.hasDate as boolean)
  const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])

  // All possible widgets for overview
  const overview: WidgetKey[] = [
    'ai-overview-summary',
    'themes-concepts',
    'nps-timeline',
    'score-timeline',
    'sentiment-timeline',
    'segments',
    'segment-correlation',
    'pivot-table',
    'quadrant',
    'timeline',
  ]

  // All possible widgets for trial overview
  const trialOverview: WidgetKey[] = [
    'ai-overview-summary',
    'themes-concepts',
    'nps-timeline',
    'score-timeline',
    'sentiment-timeline',
    'segments',
  ]

  // All possible widgets for drilldown
  const drilldown: WidgetKey[] = [
    'ai-summary',
    'themes-concepts',
    'nps-timeline',
    'score-timeline',
    'sentiment-timeline',
    'context-network',
    'verbatims',
    'segments',
    'timeline',
    'key-phrases',
    'query-details',
  ]

  // All possible widgets for trial drilldown
  const trialDrilldown: WidgetKey[] = [
    'ai-summary',
    'themes-concepts',
    'nps-timeline',
    'score-timeline',
    'sentiment-timeline',
    'context-network',
    'verbatims',
    'segments',
    'timeline',
  ]

  // Special conditions for enabling widgets (e.g date, NPS fields)
  const enabledWidgets = computed<Record<WidgetKey, boolean>>(() => ({
    'ai-overview-summary': site.value.ai_use,
    'ai-summary': site.value.ai_use,
    'themes-concepts': savedQueries.value.length > 0,
    'nps-timeline': hasNPS.value,
    'score-timeline': hasNumericFields.value || hasScoreFields.value,
    'sentiment-timeline': hasSentiment.value,
    'context-network': true,
    'verbatims': true,
    'segments': true,
    'segment-correlation': true,
    'pivot-table': true,
    'key-phrases': true,
    'query-details': drilldownType.value === 'theme',
    'timeline': hasDate.value,
    'quadrant': hasNPS.value || hasSentiment.value || hasNumericFields.value,
  }))

  // Check if a widget is hidden in the dashboard config
  const isHidden = (key: WidgetKey) => {
    const view = drilldownType.value ? 'drilldown' : 'overview'
    const visible = config.value[view].find((w) => w.name === key)?.visible ?? true
    return !visible
  }

  const widgetList = computed<(typeof WidgetList)[number][]>(() => {
    if (mode.value === WorkbenchMode.TrialOverview) {
      return WidgetList.filter(({ key }) => trialOverview.includes(key) && enabledWidgets.value[key] && !isHidden(key))
    } else if (mode.value === WorkbenchMode.TrialDrilldown) {
      return WidgetList.filter(({ key }) => trialDrilldown.includes(key) && enabledWidgets.value[key] && !isHidden(key))
    } else if (mode.value === WorkbenchMode.Overview) {
      return WidgetList.filter(({ key }) => overview.includes(key) && enabledWidgets.value[key] && !isHidden(key))
    } else if (mode.value === WorkbenchMode.Drilldown) {
      return WidgetList.filter(({ key }) => drilldown.includes(key) && enabledWidgets.value[key] && !isHidden(key))
    }
    return []
  })

  return { widgetList }
}

const getZoomRoute = (route: RouteLocationNormalizedGeneric, widget: string) => {
  return {
    name: `${String(route.name)}-zoom`,
    query: route.query,
    params: {
      ...route.params,
      zoomWidgetKey: widget,
    },
  }
}

// Compute event handlers for each widget.
export const useWidgetEvents = (
  validFilterRows: ComputedRef<ChrysalisFilter[]>,
  drilldownType: Ref<Drilldown>,
  drilldownQuery: ComputedRef<SavedQuery | ExpandedGroup | null>,
  fetchedData: FetchedData,
  toggleFilter: (field: string, segment: string) => void,
  trialMode: boolean,
  viewerMode: boolean,
) => {
  const store = useStore()
  const route = useRoute()

  const dashboard = computed(() => store.getters.currentDashboard as Dashboard)
  const currentModel = computed(() => store.getters.currentModel as Model)
  const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])
  const dashboardWidgetConfig = computed(() => store.getters.dashboardWidgetConfig as DashboardConfig['widgets'])

  const dateFieldNames = computed(() => {
    return currentModel.value.dateFields.map((f) => f.name) ?? []
  })

  let prefix = 'analysis-workbench'
  if (trialMode) prefix = 'trial-results'
  if (viewerMode) prefix = 'viewer-workbench'

  const drilldown =
    (type: 'theme' | 'concept' | 'theme_group' | 'segment') =>
    (...args: (number | string)[]) => {
      if (type === 'theme') {
        router.push({
          name: `${prefix}-drilldown-theme`,
          params: { drilldownId: args[0].toString() },
        })
      } else if (type === 'theme_group') {
        router.push({
          name: `${prefix}-drilldown-theme-group`,
          params: { drilldownId: args[0].toString() },
        })
      } else if (type === 'segment') {
        router.push({
          name: `${prefix}-drilldown-segment`,
          params: { fieldName: args[0].toString(), segment: args[1].toString() },
        })
      } else if (type === 'concept') {
        router.push({
          name: `${prefix}-drilldown-concept`,
          params: { concept: args[0].toString() },
        })
      }
    }

  const configChanged = (name: WidgetName, config: AnyWidgetConfig): void => {
    const widgets = cloneDeep(dashboardWidgetConfig.value)
    const view = drilldownType.value ? 'drilldown' : 'overview'

    let foundOne = false
    const viewConfig = widgets[view].map((widget) => {
      if (widget.name !== name) return widget
      foundOne = true
      return {
        ...widget,
        ...config,
      }
    })

    if (!foundOne) {
      const defaultConf = defaultConfig().widgets[view].find((w) => w.name === name)
      if (!defaultConf) throw new Error(`Widget ${name} not found in config!`)
      viewConfig.push({
        ...defaultConf,
        ...config,
      })
    }

    store.commit(SET_WIDGET_CONFIG, {
      widgets: {
        ...widgets,
        [view]: viewConfig,
      },
    })
  }

  const goToPivot = (col: string, row: string) => {
    configChanged('pivot-table', {
      options: {
        colFields: [col],
        rowFields: [row],
      },
    } as AnyWidgetConfig)

    router.push(getZoomRoute(route, 'pivot-table'))
  }

  const addIntersectConcept = (concept: string) => {
    const replacementConcepts = addValueToQueryParam(route, 'intersect', concept)
    if (replacementConcepts === undefined) return
    router.push({
      path: route.path,
      query: {
        ...route.query,
        intersect: replacementConcepts,
      },
    })
  }

  const widgetEvents = computed<Record<WidgetKey, WidgetEvents>>(() => {
    const requestWidgetData = fetchWidgetData.bind(
      null,
      validFilterRows,
      fetchedData,
      dashboard.value.project,
      dashboard.value.analysis,
      drilldownQuery,
    )
    const requestContextNetwork = fetchContextNetwork.bind(
      null,
      validFilterRows,
      fetchedData,
      dateFieldNames,
      savedQueries,
      dashboard.value.project,
      dashboard.value.analysis,
    )
    const requestVerbatims = fetchVerbatims.bind(
      null,
      fetchedData,
      savedQueries,
      dashboard.value.project,
      dashboard.value.analysis,
    )

    return {
      'verbatims': {
        requires: requestVerbatims,
      },
      'ai-overview-summary': {
        'go-to-theme': drilldown('theme'),
        'go-to-theme-group': drilldown('theme_group'),
      },
      'ai-summary': {},
      'themes-concepts': {
        'requires': requestWidgetData('pivot'),
        'requires-phrases': requestWidgetData('phrases'),
        'go-to-theme': drilldown('theme'),
        'go-to-theme-group': drilldown('theme_group'),
        'config-changed': (config) => configChanged('themes-concepts', config),
      },
      'nps-timeline': {
        'requires': requestWidgetData('pivot'),
        'config-changed': (config) => configChanged('nps-timeline', config),
      },
      'score-timeline': {
        'requires': requestWidgetData('pivot'),
        'config-changed': (config) => configChanged('score-timeline', config),
      },
      'sentiment-timeline': {
        'requires': requestWidgetData('pivot'),
        'config-changed': (config) => configChanged('sentiment-timeline', config),
      },
      'segments': {
        'requires': requestWidgetData('pivot'),
        'go-to-theme': drilldown('theme'),
        'go-to-theme-group': drilldown('theme_group'),
        'go-to-segment': drilldown('segment'),
        'segment-clicked': toggleFilter,
        'config-changed': (config) => configChanged('segments', config),
      },
      'context-network': {
        'requires': requestContextNetwork,
        'config-changed': (config) => configChanged('context-network', config),
        'go-to-concept': drilldown('concept'),
        'add-concept': addIntersectConcept,
      },
      'timeline': {
        'requires': requestWidgetData('pivot'),
        'config-changed': (config) => configChanged('timeline', config),
      },
      'segment-correlation': {
        'requires': requestWidgetData('correlations'),
        'config-changed': (config) => configChanged('segment-correlation', config),
        'go-to-theme': drilldown('theme'),
        'toggle-filter': toggleFilter,
        'go-to-pivot': goToPivot,
      },
      'pivot-table': {
        'requires': requestWidgetData('pivot'),
        'config-changed': (config) => configChanged('pivot-table', config),
      },
      'quadrant': {
        'requires': requestWidgetData('pivot'),
        'config-changed': (config) => configChanged('quadrant', config),
        'go-to-theme': drilldown('theme'),
        'go-to-theme-group': drilldown('theme_group'),
        'go-to-segment': drilldown('segment'),
        'go-to-concept': drilldown('concept'),
      },
      'key-phrases': {
        'requires': requestWidgetData('phrases'),
        'config-changed': (config) => configChanged('key-phrases', config),
        'add-concept': addIntersectConcept,
      },
      'query-details': {},
    }
  })

  return { widgetEvents }
}

// Compute props for each widget.
export const useWidgetProps = (
  isLoading: Ref<boolean>,
  drilldownType: Ref<Drilldown>,
  baseQuery: ComputedRef<QueryType>,
  drilldownQuery: ComputedRef<SavedQuery | ExpandedGroup | null>,
  validFilterRows: ComputedRef<ChrysalisFilter[]>,
  fetchedData: FetchedData,
  coverageStats: Ref<CoverageStats>,
  groupBy: Ref<string | null>,
  zoomWidgetKey: Ref<string | null>,
) => {
  const store = useStore()
  const route = useRoute()

  const dashboard = computed(() => store.getters.currentDashboard as Dashboard)
  const site = computed(() => store.getters.currentSite as Site)
  const currentUser = computed(() => store.getters.currentUser as UserProfile | null)
  const currentModel = computed(() => store.getters.currentModel as Model)
  const hasNumericFields = computed(() => store.getters.hasNumericFields as boolean)
  const hasSentiment = computed(() => store.getters.hasSentiment as boolean)
  const hasNPS = computed(() => store.getters.hasNPS as boolean)
  const hasDate = computed(() => store.getters.hasDate as boolean)
  const expandedDashboardQueries = computed(() => store.getters.expandedDashboardQueries as SavedQuery[])
  const sortedFieldsUnlimited = computed(() => store.getters.sortedFieldsUnlimited as SchemaColumn[])
  const sortedFieldsLimited = computed(() => store.getters.sortedFieldsLimited as SchemaColumn[])
  const sortedSegmentsForFieldsLimited = computed(() => store.getters.sortedSegmentsForFieldsLimited as SchemaColumn[])
  const defaultDateField = computed(() => store.getters.defaultDateField as string)
  const dashboardQueries = computed(() => store.getters.dashboardQueries as SavedQuery[])
  const dashboardWidgetConfig = computed(() => store.getters.dashboardWidgetConfig as DashboardConfig['widgets'])
  const expandedDashboardThemeGroups = computed(() => store.getters.expandedDashboardThemeGroups as ExpandedGroup[])
  const getFirstSchemaFieldNameWithType = computed(
    () => store.getters.getFirstSchemaFieldNameWithType as (s: string) => string | null,
  )

  const conceptQuery = computed<QueryType | null>(() => {
    if (drilldownType.value !== 'concept') return null
    const concepts: string[] = []
    const includes = concepts.map((c: string) => {
      return {
        type: 'match_any',
        includes: [{ type: 'text', value: c }],
      }
    })
    return {
      type: 'match_all',
      includes: includes,
      excludes: [],
    }
  })

  const sortedConceptQueries = computed<Partial<SavedQuery>[]>(() => {
    return currentModel.value.sortedConcepts
      .slice()
      .sort((a, b) => a.frequencyRank - b.frequencyRank)
      .map((c: { name: string; concept_terms: string[] }) => ({
        name: c.name,
        query_value: {
          type: 'match_any',
          includes: c.concept_terms?.map((term) => {
            return {
              type: 'text',
              value: term,
            }
          }),
        },
      }))
  })

  const dateFieldNames = computed(() => {
    return currentModel.value.dateFields.map((f) => f.name) ?? []
  })

  const filterQuery = computed<ChrysalisQueryType>(() => ({
    name: 'filter',
    value: QueryUtils.convertDashboardFiltersToBotanicQueries(validFilterRows.value, dateFieldNames.value),
  }))

  // This is the name of the group widgets will look for in the pivot data.
  const displayGroup = computed(() => {
    if (drilldownType.value && drilldownQuery.value) {
      if (['theme', 'theme_group'].includes(drilldownType.value)) {
        return drilldownQuery.value.name
      }
    }
    return 'overall__'
  })

  const dashboardType = computed(() => {
    if (drilldownType.value === 'theme') return 'query'
    if (drilldownType.value === 'theme_group') return 'theme-group'
    if (drilldownType.value === 'concept') return 'concept'
    return 'overview'
  })

  const getConfig = <T extends WidgetName>(widget: T) => {
    return getWidgetConfig(
      widget,
      dashboardType.value == 'overview' ? 'overview' : 'drilldown',
      dashboardWidgetConfig.value,
    ) as WidgetConfig<T>
  }

  const segmentFields = computed(() => {
    let is_defined = (s: SchemaColumn) => s.index !== undefined && s.index !== null
    // O(1) structure to lookup field membership in the "limited" fields.
    const limited = new Set(sortedFieldsLimited.value.filter(is_defined).map((f) => f.name))
    // Now iterate over ALL fields, returning a structure that includes
    // whether the field is also part of the limited set.
    return sortedFieldsUnlimited.value.filter(is_defined).map(
      (f) =>
        ({
          name: f.name,
          type: f.type,
          typename: f.typename,
          index: f.index,
          unlimited: !limited.has(f.name),
        }) as SchemaColumn,
    )
  })

  const widgetProps = computed<WidgetPropsMap | null>(() => {
    if (isLoading.value) return null
    return {
      'ai-overview-summary': {
        dashboard: dashboard.value,
        site: site.value,
        hasNps: hasNPS.value,
        hasSentiment: hasSentiment.value,
        mergedFilters: validFilterRows.value,
        themes: expandedDashboardQueries.value.concat(expandedDashboardThemeGroups.value as SavedQuery[]),
        automaticFetch: true,
        isStaff: currentUser.value?.is_staff ?? false,
        schema: dashboard.value.project.schema,
      },
      'ai-summary': {
        dashboardId: dashboard.value.id,
        projectId: dashboard.value.project.id,
        currentSite: site.value,
        currentProject: dashboard.value.project,
        currentAnalysis: dashboard.value.analysis,
        query: drilldownQuery.value?.query_value,
        mergedFilters: validFilterRows.value,
        savedQueries: expandedDashboardQueries.value.concat(expandedDashboardThemeGroups.value as SavedQuery[]),
        themeName: drilldownQuery.value?.name,
        automaticFetch: true,
        isStaff: currentUser.value?.is_staff ?? false,
      },
      'themes-concepts': {
        exportName: dashboard.value.name,
        hasNps: hasNPS.value,
        hasNumericFields: hasNumericFields.value,
        hasSentiment: hasSentiment.value,
        isFiltered: validFilterRows.value.length > 0,
        schema: dashboard.value.project.schema,
        dashboardFilters: validFilterRows.value,
        analysis: dashboard.value.analysis,
        project: dashboard.value.project,
        baseQuery: drilldownQuery.value,
        config: getConfig('themes-concepts'),
        isZoomed: zoomWidgetKey.value === 'themes-concepts',
        zoomToRoute: getZoomRoute(route, 'themes-concepts'),
        visibleThemeIds: dashboardQueries.value.map((q) => q.id),
      },
      'nps-timeline': {
        group: displayGroup.value,
        overallData: fetchedData['nps-timeline-overall'],
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        weekStart: dashboard.value.project.week_start,
        exportName: dashboard.value.name,
        dayFirstDates: dashboard.value.project.day_first_dates,
        aiUse: site.value.ai_use ?? true,
        isStaff: currentUser.value?.is_staff ?? false,
        config: getConfig('nps-timeline'),
        ...fetchedData['nps-timeline'],
      },
      'score-timeline': {
        exportName: dashboard.value.name,
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        segmentFields: segmentFields.value,
        weekStart: dashboard.value.project.week_start,
        data: fetchedData['score-timeline']?.data,
        status: fetchedData['score-timeline']?.status,
        overallData: fetchedData['score-timeline-overall']?.data,
        group: displayGroup.value,
        schema: dashboard.value.project.schema,
        dashboardFilters: validFilterRows.value,
        hasDate: hasDate.value,
        dayFirstDates: dashboard.value.project.day_first_dates,
        config: getConfig('score-timeline'),
      },
      'sentiment-timeline': {
        ...fetchedData['sentiment-timeline'],
        group: displayGroup.value,
        overallData: fetchedData['sentiment-timeline-overall'],
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        weekStart: dashboard.value.project?.week_start,
        exportName: dashboard.value.name,
        dayFirstDates: dashboard.value.project.day_first_dates,
        aiUse: site.value.ai_use,
        isStaff: currentUser.value?.is_staff ?? false,
        config: getConfig('sentiment-timeline'),
      },
      'segments': {
        group: displayGroup.value,
        filterQuery:
          dashboardType.value === 'overview' && validFilterRows.value.length > 0 ? filterQuery.value : undefined,
        exportName: dashboard.value.name,
        data: fetchedData['segmentation'],
        // Only pass in actual fields (e.g not sentiment)
        segmentFields: segmentFields.value,
        hasNps: hasNPS.value,
        mode: dashboardType.value,
        maxRows: 11,
        footerText: {
          'overview': 'Calculated relative to overall data (including filters).',
          'concept': 'Calculated relative to this concept (including filters).',
          'query': 'Calculated relative to this theme (including filters).',
          'theme-group': 'Calculated relative to this theme group (including filters).',
        }[dashboardType.value],
        allowSegmentDrilldown: true,
        config: getConfig('segments'),
        isZoomed: zoomWidgetKey.value === 'segments',
        zoomToRoute: getZoomRoute(route, 'segments'),
      },
      'timeline': {
        ...(drilldownType.value ? fetchedData['timeline_query'] : fetchedData['timeline']),
        fieldFrequency: fetchedData['field_frequency'],
        exportName: dashboard.value.name,
        hasNps: hasNPS.value,
        hasSentiment: hasSentiment.value,
        hasNumericFields: hasNumericFields.value,
        queries: expandedDashboardQueries.value,
        themeGroups: expandedDashboardThemeGroups.value,
        segmentFields: segmentFields.value,
        viewingQuery: !!drilldownType.value,
        conceptQuery: conceptQuery.value,
        dateFields: currentModel.value.dateFields,
        defaultDateField: defaultDateField.value,
        weekStart: dashboard.value?.project?.week_start,
        sortedSegmentsForFieldsLimited: sortedSegmentsForFieldsLimited.value,
        group: displayGroup.value,
        schema: dashboard.value.project.schema,
        dayFirstDates: dashboard.value.project.day_first_dates,
        aiUse: site.value.ai_use,
        isStaff: currentUser.value?.is_staff ?? false,
        dashboardFilters: validFilterRows.value,
        config: getConfig('timeline'),
        isZoomed: zoomWidgetKey.value === 'timeline',
        zoomToRoute: getZoomRoute(route, 'timeline'),
      },
      'context-network': {
        exportName: dashboard.value.name,
        query: baseQuery.value,
        viewTitle: drilldownQuery.value?.name ?? 'Overall',
        allowDrilldown: false,
        ...fetchedData['context-network'],
        config: getConfig('context-network'),
      },
      'verbatims': {
        query: baseQuery.value,
        groupByField: dashboard.value.groupby_field,
        savedQueries: dashboardQueries.value,
        hasNps: hasNPS.value,
        hasSentiment: hasSentiment.value,
        hasDate: hasDate.value,
        hasFiles: dashboard.value.project.file_based,
        npsFieldName: getFirstSchemaFieldNameWithType.value('NPS') ?? '',
        modelTopics: currentModel.value.topics,
        modelTerms: currentModel.value.terms,
        modelColors: currentModel.value.conceptColours,
        showAnnotations: dashboard.value.project.show_verbatim_annotations,
        sentimentClassifier: dashboard.value.project.sentiment_classifier,
        ...fetchedData['verbatims'],
        config: getConfig('verbatims'),
        isZoomed: zoomWidgetKey.value === 'verbatims',
        zoomToRoute: getZoomRoute(route, 'verbatims'),
      },
      'segment-correlation': {
        queries: expandedDashboardQueries.value,
        themeGroups: expandedDashboardThemeGroups.value,
        schema: dashboard.value.project.schema,
        config: getConfig('segment-correlation'),
        goToPivot: () => {}, // TODO:
        documentCount: coverageStats.value.totalRecords,
        documentQuery: coverageStats.value.records,
        totalDocsCount: dashboard.value.project.data_units,
        dashboardId: dashboard.value.id,
        exportName: dashboard.value.name,
        groupbyNotSupported: !!groupBy.value,
        hasNps: hasNPS.value,
        toQueryRoute: {
          name: 'analysis-dashboard-query-view',
          params: { analysisId: dashboard.value.analysis.id, projectId: dashboard.value.project.id, queryId: null },
          query: { filters: route.query.filters },
        },
        ...fetchedData['segment-correlation'],
        isZoomed: zoomWidgetKey.value === 'segment-correlation',
        zoomToRoute: getZoomRoute(route, 'segment-correlation'),
      },
      'pivot-table': {
        baseQuery: [],
        schema: dashboard.value.project.schema,
        config: getConfig('pivot-table'),
        savedQueries: expandedDashboardQueries.value,
        themeGroups: expandedDashboardThemeGroups.value,
        currentModel: currentModel.value,
        concepts: sortedConceptQueries.value,
        exportName: dashboard.value.name,
        dashboardId: dashboard.value.id,
        hasSentiment: hasSentiment.value,
        hasNps: hasNPS.value,
        weekStart: dashboard.value?.project?.week_start,
        ...fetchedData['pivot-table'],
        isZoomed: zoomWidgetKey.value === 'pivot-table',
        zoomToRoute: getZoomRoute(route, 'pivot-table'),
      },
      'quadrant': {
        data: {
          queries: fetchedData['quadrant__queries'],
          fields: fetchedData['quadrant__fields'],
        },
        exportName: dashboard.value.name,
        hasNps: hasNPS.value,
        hasNumericFields: hasNumericFields.value,
        hasSentiment: hasSentiment.value,
        segmentFields: segmentFields.value,
        sortedSegmentsPerField: sortedSegmentsForFieldsLimited.value,
        queries: expandedDashboardQueries.value,
        themeGroups: expandedDashboardThemeGroups.value,
        concepts: sortedConceptQueries.value,
        config: getConfig('quadrant'),
        schema: dashboard.value.project.schema,
        dashboardFilters: validFilterRows.value,
        isZoomed: zoomWidgetKey.value === 'quadrant',
        zoomToRoute: getZoomRoute(route, 'quadrant'),
      },
      'key-phrases': {
        queries: expandedDashboardQueries.value,
        group: displayGroup.value,
        exportName: dashboard.value.name,
        showDrilldown: true,
        config: getConfig('key-phrases'),
        groupbyNotSupported: !!groupBy.value,
        viewTitle: drilldownQuery.value?.name ?? 'Overall',
        ...fetchedData['key-phrases'],
        isZoomed: zoomWidgetKey.value === 'key-phrases',
        zoomToRoute: getZoomRoute(route, 'key-phrases'),
      },
      'query-details': {
        query: drilldownQuery.value,
        savedQueries: expandedDashboardQueries.value,
        projectId: dashboard.value.project.id,
        analysisId: dashboard.value.analysis.id,
        dateFieldIndex: currentModel.value.dateFieldIndex || {},
        conceptColours: currentModel.value.conceptColours,
        userIsViewer: !!currentUser.value?.viewer,
      },
    }
  })

  return { widgetProps }
}

<template>
  <FloatingPanel v-if="hasContent" :visible="visible" :x="xPos" :y="yPos">
    <div class="interaction-menu">
      <slot />
    </div>
  </FloatingPanel>
</template>
<script lang="ts">
import { defineComponent, ref, watch, nextTick, PropType, onMounted, onUnmounted } from 'vue'
import { debounce } from 'lodash'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'

type Parent = HTMLElement | SVGElement | undefined

export default defineComponent({
  components: { FloatingPanel },
  props: {
    hasContent: {
      type: Boolean,
      default: true,
    },
    getParentElement: {
      type: Function as PropType<() => Parent | Parent[]>,
      default: () => () => undefined,
    },
  },
  emits: ['closed', 'opened', 'self-close'],
  setup(props, { emit }) {
    const visible = ref(false)
    const xPos = ref(0)
    const yPos = ref(0)

    watch(
      () => visible.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          if (!newVal) emit('closed')
          if (newVal) emit('opened')
        }
      },
    )

    const onClick = (event: Event) => {
      visible.value = !visible.value
      if (!visible.value) return

      const parent = props.getParentElement()
      if (parent === undefined) return

      xPos.value = (event as MouseEvent).clientX
      yPos.value = (event as MouseEvent).clientY
    }

    const getParents = () => {
      return ([] as Parent[]).concat(props.getParentElement())
    }

    const clickedOutside = (e: MouseEvent) => {
      const parents = getParents()
      const validTarget = e.target instanceof HTMLElement || e.target instanceof SVGElement
      if (parents.length === 0 || !validTarget) return
      const contained = parents.some((p) => p?.contains(e.target as Node))
      if (!contained) {
        visible.value = false
      }
    }

    const handleScroll = debounce(
      () => {
        if (visible.value) {
          visible.value = false
          emit('self-close')
        }
      },
      250,
      { leading: true },
    )

    const addParentClickHandler = () => {
      const parents = getParents()
      parents.forEach((p) => p?.addEventListener('click', onClick))
    }

    watch(() => props.getParentElement, addParentClickHandler)

    onMounted(() => {
      nextTick(addParentClickHandler)
      if (typeof window !== 'undefined') {
        document.addEventListener('wheel', handleScroll)
      }
    })

    onUnmounted(() => {
      const parents = getParents()
      parents.forEach((p) => p?.removeEventListener('click', onClick))
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', clickedOutside)
        document.removeEventListener('wheel', handleScroll)
      }
    })

    if (typeof window !== 'undefined') {
      document.addEventListener('click', clickedOutside)
    }

    return {
      visible,
      xPos,
      yPos,
    }
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.wrapper
  position: relative
.interaction-menu
  background: $white
  padding: 20px 30px
  box-shadow: 0px 1px 5px 1px rgba(0, 1, 1, 0.1)
  z-index: 999
  border-radius: 5px
  width: max-content
  ::v-deep hr
    border: 0
    border-top: 1px solid $grey
    padding: 5px 0
  ::v-deep button
    border: 0
    padding: 0
    display: block
    font-size: 16px
    line-height: 24px
    color: $text-black
    cursor: pointer
    white-space: nowrap
    &:not(:last-child)
      margin-bottom: 10px
    &:hover
      background: unset
      color: lighten($text-black, 30%)
    &:focus
      outline: none
      border: none
</style>

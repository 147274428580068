<template>
  <div id="analysis-list" ref="analysisList">
    <div v-if="isDataProcessing" class="loading-mask">
      <img class="ui centered image" src="../../../assets/img/logo-square-inverse-transparent.png" />
      <div>
        Your data is processing.
        <br />Please wait.
      </div>
      <div class="ui small active inline inverted loader"></div>
    </div>
    <abstract-sortable-grid
      v-else
      ref="grid"
      :grid-data="data"
      :working="gettingAnalyses"
      :fetch-data-fn="getAnalyses"
      :sort-options="sortOptions"
      :page-number="pageNumber"
    >
      <!-- Header -->
      <template #header>
        <h3 class="ui left floated header">Analysis</h3>
        <!-- This outer div to absorb the "height" style on .ui.segment#gridwrapper .row:nth-child(1) -->
        <div>
          <bf-button
            v-show="hasAnalyses"
            color="blue"
            size="large"
            :route-to="{ name: 'analysis-create', params: { projectId: projectId } }"
          >
            <i class="kapiche-icon-microscope"></i><span class="button-label">New analysis</span>
          </bf-button>
        </div>
      </template>

      <!-- Rows -->
      <template #rows>
        <template v-if="hasAnalyses">
          <div
            v-for="analysis in analyses"
            :key="analysis.id"
            class="row"
            :class="{ clickable: isAnalysisViewable(analysis.id) }"
            @mouseleave="onMouseLeave($event)"
          >
            <router-link
              :to="
                isStatusClickable(analysis.status) ?
                  { name: 'view-analysis', params: { projectId: analysis.project, analysisId: analysis.id } }
                : ''
              "
              :class="{ 'disabled-link': !isStatusClickable(analysis.status) }"
              @click="handleAnalysisClick($event, analysis)"
            >
              <div class="name">{{ analysis.name }}<br /></div>
              <!-- Error info -->
              <div class="subtext" @click.prevent>
                <div
                  v-if="analysis.status.toUpperCase() === 'ERROR'"
                  class="subtext-detail"
                  :class="{ 'error-detail': errorDetail(analysis) }"
                >
                  <span class="error"
                    >{{ displayError(analysis) }}
                    <span v-if="errorDetail(analysis)">
                      <div class="tooltip">
                        <p>
                          {{ errorDetail(analysis) }}
                        </p>
                      </div>
                    </span>
                  </span>
                  <span>
                    (<a class="error-info-link" target="_blank" :href="CONST.intercom_links.ANALYSIS_ERRORS" @click.stop
                      >Click for info</a
                    >)
                  </span>
                </div>
                <!-- General info -->
                <div v-else class="subtext-detail">
                  <!-- Date range -->
                  <span v-if="hasDate() && getDates(analysis)" class="date-period">
                    {{ getDates(analysis) }}
                  </span>
                  <!-- Last updated -->
                  <span v-else class="date-period"> Modified {{ formatDate(analysis.modified) }} </span>
                  <!-- Warning info -->
                  <span v-if="hasLowRecordCount(analysis)" class="warning">
                    Low Record Count (?)
                    <div class="tooltip">
                      <p>Low records may result in very small storyboards and number of concepts.</p>
                    </div>
                  </span>
                  <div class="grow"></div>
                </div>
              </div>
            </router-link>
            <div class="status actions" @click.prevent>
              <!-- Menu -->
              <div :class="{ hidden: !analysisSubmenuVisible(analysis) }" class="ui dropdown">
                <div class="text" @click.stop="openDropdown($event)">...</div>
                <div class="menu">
                  <div class="item" @click.stop="showDeleteAnalysisModal(analysis)">Delete Analysis</div>
                  <div class="item" @click.stop="showEditAnalysisNameModal(analysis)">Edit name</div>
                  <div v-if="featureFlags.analysis_clone" class="item" @click.stop="cloneAnalysis(analysis)">Clone</div>
                  <div
                    v-if="currentUser && currentUser.is_staff"
                    class="item staff-only"
                    @click.stop="toggleAnalysisStale(analysis)"
                  >
                    Toggle stale
                  </div>
                  <div
                    v-if="currentUser && currentUser.is_staff"
                    class="item staff-only"
                    @click.stop="rerunAnalysis(analysis)"
                  >
                    Rerun Analysis
                  </div>
                </div>
              </div>
              <div
                v-if="isStatusClickable(analysis.status)"
                class="analysis-info"
                @mouseenter="onAnalysisInfoHover(analysis, $event)"
                @mouseleave="hoveredAnalysisInfo = null"
              >
                <div class="subtext">Show Info</div>
                <floating-panel :visible="hoveredAnalysisInfo === analysis.id" :y="mouseY" :x="mouseX">
                  <data-tool-tip
                    v-if="hoveredAnalysisInfo === analysis.id"
                    :data="getAnalysisInfoForTooltip(analysis)"
                  />
                </floating-panel>
              </div>
              <!-- Normal status flags -->
              <div
                v-if="analysis.status.toUpperCase() === 'PROCESSING' || analysis.status.toUpperCase() === 'UPDATING'"
                class="status-processing analysis"
              >
                <div class="ui active tiny inline loader"></div>
                &nbsp;
                {{ analysis.status.toUpperCase() === 'PROCESSING' ? 'Analyzing' : 'Updating' }}
              </div>
              <div v-else-if="analysis.status.toUpperCase() === 'CREATED'">Queued</div>
              <div v-else-if="analysis.status.toUpperCase() === 'PENDING'">Update Queued</div>
              <div v-else-if="analysis.status.toUpperCase() === 'ERROR'" class="status-error">Error</div>
              <!-- Update stale data -->
              <div v-else-if="analysis.stale && !isDataProcessing" class="status-update">
                <a href="javascript:void(0)" @click.stop="showUpdateAnalysisModal(analysis)">Update</a>
              </div>
              <!-- Empty (ready) -->
              <div v-else>&nbsp;</div>
            </div>
          </div>
        </template>
        <div v-else-if="analyses" class="row empty" @click="navigateToCreateAnalysis">
          <i class="kapiche-icon-microscope"></i>
          <i class="kapiche-icon-plus"></i>
          <div>Your data is ready.<br />Click here to create an analysis!</div>
        </div>
      </template>
    </abstract-sortable-grid>

    <!-- Delete confirmation -->
    <modal :visible="showDeleteModal" @close="showDeleteModal = false">
      <template #header>
        Are you sure you want to delete this analysis: '{{ analysisToDelete ? analysisToDelete.name : '' }}'?
      </template>
      <template #content>
        <div class="description center">
          <p>Deleting this analysis will <strong>permanently remove</strong>:</p>
          <p>All themes and theme groups, Dashboards, Email Digests, and any related filters.</p>
          <p><strong>Once deleted, these cannot be recovered.</strong></p>

          <div class="delete-confirmation mt-4">
            <p>To confirm, type <strong>DELETE</strong> in the box below.</p>
            <div class="ui input">
              <input
                type="text"
                v-model="deleteConfirmText"
                placeholder="Type DELETE to confirm"
                class="delete-confirm-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #buttons>
        <button class="ui cancel basic button" @click="showDeleteModal = false">Cancel</button>
        <button class="ui negative button" :disabled="deleteConfirmText !== 'DELETE'" @click="deleteAnalysis()">
          Confirm
        </button>
      </template>
    </modal>
    <!-- Update confirmation -->
    <modal :visible="showUpdateModal" @close="showUpdateModal = false">
      <template #header>
        Do you want to update the analysis '{{ analysisToUpdate ? analysisToUpdate.name : '' }}'?
      </template>
      <template #content>
        <div class="description">
          Updating an analysis cannot be undone and causes all counts, correlations and queries to reflect the project's
          data state at the moment the update was performed.
        </div>
      </template>
      <template #buttons>
        <button class="ui cancel basic button" @click="showUpdateModal = false">No</button>
        <button class="ui positive button" @click="rerunAnalysis(analysisToUpdate)">Yes, update the analysis</button>
      </template>
    </modal>

    <modal-edit-text
      ref="AnalysisEditName"
      :header="'Edit Analysis Name'"
      :caption="'Change the analysis name to'"
      @save="changeAnalysisName"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import $ from 'jquery'
import { mapGetters } from 'vuex'

import AbstractSortableGrid from './AbstractSortableGrid.vue'
import Project from 'src/api/project'
import { LOAD_PROJECT } from 'src/store/types'
import Modal from 'components/Modal.vue'
import ModalEditText from 'components/widgets/ModalEditText.vue'
import { formatDate } from 'src/utils/dates'
import DataUtils from 'src/utils/data'
import FormatUtils from 'src/utils/formatters'
import { debounce } from 'lodash'
import { BfButton } from 'components/Butterfly'
import DataToolTip from 'components/DataWidgets/DataToolTip/DataToolTip.vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'

export default defineComponent({
  components: {
    AbstractSortableGrid,
    Modal,
    ModalEditText,
    BfButton,
    DataToolTip,
    FloatingPanel,
  },
  props: {},
  data() {
    return {
      pageNumber: 1,
      pageSize: 4,
      sortBy: '',
      gettingAnalyses: false,
      analysisToDelete: null,
      analysisToUpdate: null,
      data: {},
      sortOptions: [
        { key: '-modified', label: 'Date Modified' },
        { key: '-created', label: 'Date Created' },
        { key: 'name', label: 'Alphanumeric' },
      ],
      getAnalysesDebounced: debounce((pageNumber, sortBy) => {
        if (!this.projectId) {
          // Route might have navigated away by the time the debounce
          // fires. In that case projectId will not be defined so don't
          // bother getting Analyses for it.
          // TODO: see the TODO in the projectId computed prop
          return
        }
        return Project.getAnalyses(pageNumber, this.projectId, sortBy)
          .then((response) => {
            if (this.pageNumber === pageNumber) {
              this.data = response
            }
          })
          .catch(({ status }) => {
            // We aren't concerned about 401s here because it is either:
            // 1. The user does not have permission to view the analyses
            // 2. The user's token has expired.
            // In scenario 1 - this is handled at the project container level
            // In scenario 2 - this is handled with a prompt for reauth
            if (status !== 401) {
              throw new Error(`Unhandled API response for Project.getAnalyses, status: ${status}`)
            }
          })
          .finally(() => {
            this.gettingAnalyses = false
          })
      }, 500),
      viewAnalysisAlreadyClicked: false,
      hoveredAnalysisInfo: null,
      mouseX: 0,
      mouseY: 0,
      showDeleteModal: false,
      showUpdateModal: false,
      deleteConfirmText: '',
    }
  },
  computed: {
    ...mapGetters(['currentProject', 'featureFlags', 'currentUser']),
    analyses() {
      return this.data.results
    },
    hasAnalyses() {
      return this.analyses && this.analyses.length > 0
    },
    isDataProcessing() {
      return !!this.currentProject?.processing
    },
    projectId() {
      return this.currentProject?.id
    },
  },
  watch: {
    isDataProcessing(current, prev) {
      if (prev === true && current === false) {
        this.getAnalyses(this.pageNumber, this.sortBy, true, false)
      }
    },
  },
  mounted() {
    this.sortBy = this.sortOptions[0].key
    this.getAnalyses(1, this.sortBy, true)
    // Init analysis actions dropdowns
    this.$nextTick(() => {
      $(this.$el).find('.actions .dropdown').dropdown({
        action: 'hide',
        transition: 'drop',
      })
    })
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    async deleteAnalysis() {
      try {
        await Project.deleteAnalysis(this.projectId, this.analysisToDelete.id)
      } catch (e) {
        console.warn('Error while deleting analysis:', e)
      } finally {
        this.$analytics.track.analysis.delete(this.analysisToDelete.id)
        this.analysisToDelete = null
        this.deleteConfirmText = ''
        this.getAnalyses(this.pageNumber, this.sortBy, true)
        this.showDeleteModal = false
      }
    },
    async cloneAnalysis(analysis) {
      this.gettingAnalyses = true
      try {
        await Project.cloneAnalysis(this.projectId, analysis.id)
        await this.getAnalyses(this.pageNumber, this.sortBy, true)
      } finally {
        if (this.gettingAnalyses) {
          this.gettingAnalyses = false
        }
      }
    },
    async toggleAnalysisStale(analysis) {
      this.gettingAnalyses = true
      try {
        await Project.updateAnalysisDisplayAttributes(
          this.$store,
          {
            stale: analysis.stale ? 0 : 1,
          },
          analysis,
        )
        await this.getAnalyses(this.pageNumber, this.sortBy, true)
      } finally {
        if (this.gettingAnalyses) {
          this.gettingAnalyses = false
        }
      }
    },
    async rerunAnalysis(analysis) {
      this.gettingAnalyses = true
      try {
        const settings =
          this.featureFlags.automatic_framework_generation ?
            { automatic_theme_generation: this.featureFlags.automatic_framework_generation }
          : {}
        await Project.rerunAnalysis(analysis.project, analysis.id, settings)
        await this.getAnalyses(this.pageNumber, this.sortBy, true)
      } finally {
        if (this.gettingAnalyses) {
          this.gettingAnalyses = false
        }
        this.$analytics.track.analysis.update(analysis.id)
        this.showUpdateModal = false
        this.analysisToUpdate = null
      }
    },
    displayError(analysis) {
      if (analysis.hasOwnProperty('status_text') && analysis.status_text.toLowerCase().includes('too little data')) {
        return 'Insufficient Data'
      } else if (analysis.hasOwnProperty('status_text') && analysis.status_text.toLowerCase().includes('no words')) {
        return 'No Data Matches Analysis Filters'
      }
      return 'Unexpected Problem'
    },
    errorDetail(analysis): string | undefined {
      if (
        analysis.hasOwnProperty('status_text') &&
        analysis.status_text.toLowerCase().includes('too little data to identify enough concepts')
      ) {
        return analysis.status_text
      }
    },
    hasLowRecordCount(analysis): boolean {
      return analysis.status === 'Finished' && analysis.hasOwnProperty('data_units') && analysis.data_units < 400
    },
    number: FormatUtils.number,
    createdBy(analysis: object): string {
      let user = analysis?.created_by
      if (!user) {
        return '(missing)'
      }

      let name = user.first_name + ' ' + user.last_name
      if (name.trim()) {
        return name
      } else {
        return user.email
      }
    },
    getAnalyses(pageNumber, sortBy, force = false, background = false) {
      if (force || pageNumber !== this.pageNumber) {
        this.gettingAnalyses = !background
        this.pageNumber = pageNumber
        // Refresh project
        this.$store.dispatch({
          type: LOAD_PROJECT,
          projectId: this.projectId,
        })
        return this.getAnalysesDebounced(pageNumber, sortBy)
      }
    },
    getDates(analysis) {
      return DataUtils.parseAnalysisDates(analysis)
    },
    // Traverse project schema and check for date fields
    hasDate() {
      if (this.currentProject?.schema) {
        for (let val of this.currentProject.schema) {
          if (
            val.type === Project.COLUMN_LABELED_TYPES.get('DATE') ||
            val.type === Project.COLUMN_LABELED_TYPES.get('DATETIME')
          ) {
            return true
          }
        }
        return false
      }
    },
    isAnalysisViewable(id) {
      let analysis = this.data.results.find((a) => a.id === id)
      return !this.viewAnalysisAlreadyClicked && analysis.status.toUpperCase() === 'FINISHED'
    },
    navigateToCreateAnalysis() {
      this.$router.push({ name: 'analysis-create', params: { id: this.projectId } })
    },
    // Handle mouse leaving a row -- close its dropdown
    onMouseLeave(event) {
      let rowEl = event.target
      $(rowEl).find('.dropdown').dropdown('hide')
    },
    openDropdown(event) {
      $(event.target.parentNode).dropdown('show')
    },
    showDeleteAnalysisModal(analysis) {
      this.analysisToDelete = analysis
      this.deleteConfirmText = ''
      this.showDeleteModal = true
    },
    showUpdateAnalysisModal(analysis) {
      this.analysisToUpdate = analysis
      this.showUpdateModal = true
    },
    viewAnalysis(id) {
      if (this.viewAnalysisAlreadyClicked) {
        // It is possible that the user can click the analysis row twice
        // rapidly, like a double-click. This can happen fast enough that
        // that this click event handler gets called multiple times before
        // the $router.push actually changes the page. So we set a flag
        // to know if the analysis has already been clicked.
        return
      }

      // Look up the specific analysis by id. If that analysis is not
      // viewable, do nothing.
      let analysis = this.data.results.find((a) => a.id === id)
      if (!this.isAnalysisViewable(id)) {
        return
      }

      this.$router.push(
        {
          name: 'view-analysis',
          params: {
            projectId: analysis.project,
            analysisId: analysis.id,
          },
        },
        () => undefined,
        () => (this.viewAnalysisAlreadyClicked = false),
      )
      this.viewAnalysisAlreadyClicked = true
    },
    showEditAnalysisNameModal(analysis) {
      this.activeAnalysis = analysis
      this.proposedAnalysisName = this.activeAnalysis.name
      this.$refs.AnalysisEditName.show(analysis.name, analysis)
    },
    async changeAnalysisName(newName, analysis) {
      await Project.modifyAnalysis(analysis, { name: newName })
      await this.getAnalyses(this.pageNumber, this.sortBy, true)
    },
    analysisSubmenuVisible(analysis) {
      // Analysis submenu is only visible if the analysis is not currently
      // being run, updated, or otherwise busy - except is a user is staff
      // in which case the submenu is always visible. This allows staff
      // to change analysis status and/or rerun the analysis.
      const busyStates = ['PROCESSING', 'UPDATING']
      let busy = busyStates.includes(analysis.status.toUpperCase())
      let is_staff = this.currentUser && this.currentUser.is_staff
      return is_staff || !busy
    },
    getAnalysisInfoForTooltip(analysis) {
      const baseTextStyle = {
        color: '#95A6AC',
        fontFamily: 'Lato, sans-serif',
      }
      const baseValueStyle = {
        fontWeight: 'bold',
        fontFamily: 'Lato, sans-serif',
      }
      const formatBoolean = (value: boolean | undefined): string => {
        return value ? 'Yes' : 'No'
      }
      return [
        {
          label: { text: 'Records', style: baseTextStyle },
          value: { text: this.number(analysis.data_units), style: baseValueStyle },
        },
        {
          label: { text: 'Created Date', style: baseTextStyle },
          value: { text: this.formatDate(analysis.created), style: baseValueStyle },
        },
        ...(analysis.created_by ?
          [
            {
              label: { text: 'Created by', style: { color: '#95A6AC' } },
              value: { text: this.createdBy(analysis), style: { fontWeight: 'bold' } },
            },
          ]
        : []),
        {
          label: { text: 'Date Field', style: baseTextStyle },
          value: { text: (analysis.default_date_field || 'No Date Field').toString(), style: baseValueStyle },
        },
        {
          label: { text: 'Dashboards', style: baseTextStyle },
          value: { text: (analysis.dashboard_ids?.length || 0).toString(), style: baseValueStyle },
        },
        {
          label: { text: 'AI Themes Enabled', style: { color: '#95A6AC' } },
          value: {
            text: formatBoolean(analysis?.automatic_theme_generation),
            style: { fontWeight: 'bold' },
          },
        },
        {
          label: { text: 'Themes', style: baseTextStyle },
          value: { text: (analysis.query_count || 0).toString(), style: baseValueStyle },
        },
        {
          label: { text: 'Email Digests', style: baseTextStyle },
          value: { text: (analysis.email_digest_count || 0).toString(), style: baseValueStyle },
        },
      ]
    },
    onAnalysisInfoHover(analysis, event) {
      this.hoveredAnalysisInfo = analysis.id
      this.mouseX = event.clientX
      this.mouseY = event.clientY
    },
    isStatusClickable(status) {
      const nonClickableStatuses = ['PROCESSING', 'ERROR', 'PENDING']
      return !nonClickableStatuses.includes(status.toUpperCase())
    },
    handleAnalysisClick(event, analysis) {
      if (event.target.classList.contains('error-info-link')) {
        return
      }
      // Prevent navigation if status is not clickable
      if (!this.isStatusClickable(analysis.status)) {
        event.preventDefault()
        return
      }
    },
  },
})
</script>

<style lang="sass">
@import 'assets/kapiche.sass'

#analysis-list
  /* Data processing mask */
  .loading-mask
    background-color: #123f54
    background-image: linear-gradient(#123f54, #012a38)
    color: white
    font-size: rem(20px)
    height: rem(550px)
    line-height: rem(30px)
    padding-top: rem(150px)
    text-align: center
    img
      margin-bottom: rem(15px)
    .loader
      margin-top: rem(15px)
  .row
    overflow: visible !important
    > a
      flex: 1
      &:hover
        text-decoration: none
        .name
          text-decoration: underline !important

    .error
      color: $red
    .status-error
      color: $red
      text-transform: uppercase
    .status-update
      text-transform: uppercase
    .status-processing
      text-transform: uppercase

  .row.empty
    border-bottom: 0 !important
    color: $blue
    font-size: rem(18px)
    display: block !important
    height: auto !important
    padding-top: rem(50px) !important
    padding-bottom: rem(20px) !important
    text-align: center
    &:hover
      color: $blue-light
      cursor: pointer
    i.kapiche-icon-microscope
      font-size: rem(50px)
    i.kapiche-icon-plus
      font-size: rem(30px)
      position: absolute
      top: rem(65px)
    div
      margin-top: rem(10px)
  .tooltip
    margin: rem(5px) rem(10px) rem(0) rem(0)
    word-break: break-word
    text-transform: initial

  .subtext-detail
    color: $text-grey
    display: flex
    gap: 0 10px
    width: 100%
    > *
      text-overflow: ellipsis
      overflow: hidden
      flex: 0 1 auto
    > * + *
      min-width: rem(60px)
      flex: 0 1000000 auto
  .warning
      color: $yellow
  .warning .tooltip
      display: none
  .warning:hover .tooltip
      display: block

  .error-detail
      cursor: pointer
  .error-detail .error::after
      content: "(?)"
  .error-detail .tooltip
      display: none
  .error-detail:hover .tooltip
      display: block

  .name
    color: $text-black
  .name:hover
    .subtext-detail
      flex-wrap: wrap

  .status
    .hidden
      visibility: hidden
    .inline.loader
      vertical-align: text-bottom

  .button-label
    margin-left: rem(10px)

  .grow
    flex-grow: 1

  .analysis-info
    position: relative
    cursor: pointer
    text-align: right
    margin-left: auto

    .subtext
      text-transform: uppercase
      font-weight: bold
      font-size: 0.85714rem
      white-space: nowrap
      height: 1.8rem

      &:hover
        color: $blue

  ::v-deep(.themes-chart-tooltip)
    z-index: 3000
    max-width: 300px
    position: absolute
    color: $text-black

  .ui.segment#grid-wrapper .row .status .dropdown .text
    line-height: 0.1
    height: 1rem

  .ui.segment#grid-wrapper .row .status .dropdown
    height: 2rem

  .ui.segment#grid-wrapper .row .status
    text-transform: initial

.disabled-link
  cursor: not-allowed
  opacity: 0.7
  pointer-events: none

  .error-info-link
    cursor: pointer
    pointer-events: all
    color: $blue
    &:hover
      color: $blue
      text-decoration: underline

  .name
    color: $text-grey
    text-decoration: none !important
  &:hover
    .name
      text-decoration: none !important
.row
  > a.disabled-link
    &:hover
      .name
        text-decoration: none !important
</style>

<template>
  <div class="wrapper">
    <tour-modal
      :visible="startDialogVisible"
      :step-count="tour.stepCount"
      @close="startDialogVisible = false"
      @start-tour="startTourDialog"
    />
    <modal class="start" :visible="tourDialogVisible" @close="tourDialogVisible = false">
      <template #header> Before you start... </template>
      <template #content>
        <p>
          Let's get you orientated. You currently find yourself on a Kapiche Workbench. A lot has happened to get you
          here:
        </p>
        <div class="start-body">
          <div class="block">
            <div class="image">
              <img style="width: 120px" :src="uploadImage" />
            </div>
            <div class="content">
              <h4>1. Data upload</h4>
              <p>
                For insights you need data. In the data upload process, we figure out information about different fields
                in your data like the data type of each field. Kapiche can also optionally remove any
                personally-identifiable information (PII) automatically.
              </p>
            </div>
          </div>
          <div class="block">
            <div class="image">
              <img style="width: 115px" :src="themesImage" />
            </div>
            <div class="content">
              <h4>2. Automatic theme identification</h4>
              <p>
                Themes (topics found in the text data) are either automatically identified by Kapiche AI. This is done
                using a "bottom-up" or "inductive" methodology (<a
                  href="https://www.kapiche.com/blog/inductive-coding"
                  target="_blank"
                  >read more here</a
                >). You can edit the automatic themes to better match your own language.
              </p>
            </div>
          </div>
        </div>
        <p>Simple, quick, and automated (thanks, AI). Let's continue.</p>
      </template>
      <template #buttons>
        <div class="footer">
          <bf-button size="large" color="blue" @click="startTour">Continue to Workbench</bf-button>
        </div>
      </template>
    </modal>
    <modal :visible="showDataExportLimitModal" @close="showDataExportLimitModal = false">
      <template #header> Sorry! This export is unavailable... </template>
      <template #content>
        <div class="center">
          We currently only support this export when you have {{ number(EXPORT_LIMIT) }} records or less, but this
          Dashboard has {{ number(coverageStats.records) }}. <br /><br />
          Please <a href="javascript:window.Intercom('show');">contact us</a> if you would like data exports for this
          Dashboard.
        </div>
      </template>
    </modal>
    <template v-if="!isLoading && currentDashboard">
      <top-bar
        v-if="!trialMode"
        :theme-group-tree="themeGroupTree"
        :filter-rows="filterRows"
        :compare-filter-rows="compareFilterRows"
        :speaker-field="speakerField"
        :compare-mode="compareMode"
        :viewer-mode="viewerMode"
        @update:show-group-labels="showGroupLabels = $event"
        @export-csv="exportCSV"
        @export-ppt="exportPPT"
        @discard-changes="discardChanges"
        @reset-workbench="resetWorkbench"
      />
      <filter-bar
        ref="filterBar"
        :query-id="0"
        :query-scope="currentDashboard.project.query_scope_default"
        :query-rows="filterRows"
        :allow-themes="false"
        :allow-concepts="false"
        :allow-operators="false"
        :allow-text="false"
        :location="location"
        :filter-warnings="[]"
        @set-query-rows="filterRows = $event"
      />
      <!-- @vue-skip -->
      <div class="info-bar">
        <div>
          <div v-if="isLoadingCoverage" class="coverage-stats">
            <bf-spinner />
            Updating coverage...
          </div>
          <div v-else class="coverage-stats">
            <b>{{ coverageStats.records }} of {{ coverageStats.totalRecords }} records</b>
            &nbsp;({{ getCoveragePercent(coverageStats.records, coverageStats.totalRecords) }}%)
            <span>&bull;</span>
            <b>{{ coverageStats.verbatims }} of {{ coverageStats.totalVerbatims }} verbatims</b>
            &nbsp;({{ getCoveragePercent(coverageStats.verbatims, coverageStats.totalVerbatims) }}%)
          </div>
          <div v-if="drilldown || zoomWidgetKey" class="back-link">
            <router-link
              :to="{
                name: backRouteName,
                params: { dashboardId: dashboardId },
              }"
            >
              <icon name="chevron-left" color="#068ccc" :size="10" />
              BACK TO MAIN PAGE
            </router-link>
          </div>
        </div>
      </div>
      <!-- @vue-skip -->
      <div v-if="widgetProps" class="content-wrapper">
        <template v-if="zoomWidgetKey">
          <div v-if="!zoomedWidget">not found</div>
          <component
            :is="zoomedWidget.component"
            v-else
            ref="widgetRefs"
            v-bind="widgetProps[zoomedWidget.key]"
            v-on="widgetEvents[zoomedWidget.key]"
          />
        </template>
        <template v-else>
          <div class="widget-overview">
            <div ref="overviewRef" class="widget-nav">
              <div
                v-for="widget in widgetList"
                :key="widget.key"
                :class="[
                  'widget-link',
                  {
                    active: topWidgetId === `widget_${widget.key}`,
                  },
                ]"
                @click="scrollToWidget(`widget_${widget.key}`)"
              >
                <div
                  class="overview-icon"
                  :style="{
                    maskImage: topWidgetId === `widget_${widget.key}` ? 'none' : `url(${WidgetIcons[widget.key]})`,
                    backgroundImage:
                      topWidgetId === `widget_${widget.key}` ? `url(${WidgetIcons[widget.key]})` : 'none',
                  }"
                />
                <span>
                  {{ widget.label }}
                </span>
              </div>
            </div>
            <widget-preview v-if="trialMode" :mode="drilldown ? 'drilldown' : 'overview'" />
            <div v-if="trialMode" class="cta">
              <p>Ready to transform your approach to understanding customer feedback?</p>
              <p>
                <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert"
                  >Schedule a call now &nbsp;→</a
                >
              </p>
            </div>
            <div class="marker" :style="{ top: `${overviewMarkerOffset}px` }" />
          </div>
          <div class="widget-wrapper">
            <div v-if="drilldown" class="drill-down-title">
              {{ drilldownQuery?.name }}
              <span v-for="concept in intersectConcepts" :key="concept">
                <span>AND</span>
                {{ concept }}
              </span>
            </div>
            <div
              ref="scrollRef"
              class="widget-scroll"
              :style="{
                overflowY: tour.isActive.value ? 'hidden' : 'scroll',
              }"
            >
              <div
                v-for="widget in widgetList"
                :id="`widget_${widget.key}`"
                :key="widget.key"
                ref="widgetWrapperRefs"
                class="widget"
              >
                <component
                  :is="widget.component"
                  ref="widgetRefs"
                  v-bind="widgetProps[widget.key]"
                  v-on="widgetEvents[widget.key]"
                />
              </div>
              <p v-if="trialMode">
                Ready to transform your approach to understanding customer feedback?
                <a href="https://info.kapiche.com/meetings/ryankapiche/talk-to-a-kapiche-expert">Schedule a call</a>
                now.
              </p>
            </div>
          </div>
        </template>
      </div>
    </template>
    <!-- @vue-skip -->
    <template v-else-if="hasErrored">
      <div class="error-message">
        <h2>Something went wrong</h2>
        <p>There was an error loading this results page.</p>
      </div>
    </template>
    <!-- @vue-skip -->
    <template v-else>
      <div class="loading-wrapper">
        <bf-spinner />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  computed,
  reactive,
  provide,
  PropType,
  toRef,
  inject,
} from 'vue'
import { cloneDeep, isEqual } from 'lodash'
import { useStore } from 'vuex'
import { stringify } from 'csv-stringify'
import PptxGenJS from 'pptxgenjs'

import 'driver.js/dist/driver.css'

import emitter from 'src/event-bus'
import {
  LOAD_ANALYSIS,
  LOAD_DASHBOARD,
  SAVE_DASHBOARD_DATE_RANGE,
  SET_DASHBOARD_QUERIES,
  SET_WIDGET_CONFIG,
} from 'src/store/types'
import { Dashboard, DashboardConfig, DateRangeConfig, DateRangeTypeEnum } from 'src/types/DashboardTypes'
import { QueryLocation, QueryRow, QueryType, SavedQuery } from 'src/types/Query.types'
import FilterBar from 'components/project/analysis/results/ThemeBuilder/FilterBar.vue'
import {
  CoverageStats,
  DashboardOptions,
  Drilldown,
  fetchCoverageStats,
  FetchedData,
  useTopVisibleWidget,
  WorkbenchMode,
} from './Workbench.utils'
import { isQueryValid } from 'components/project/analysis/results/query/utils'
import QueryUtils, { mergeDashboardFiltersWithBotanicQuery } from 'src/utils/query'
import { useWidgetList, useWidgetProps, WidgetIcons, useWidgetEvents, WidgetInstance } from './WidgetProps'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { ExpandedGroup, Group, GroupOrTheme, processQueries } from 'src/pages/dashboard/Dashboard.utils'
import { SchemaColumn } from 'src/types/SchemaTypes'
import Icon from 'components/Icon.vue'
import { applyToggledSegmentsToQueryRows } from 'src/types/utils'
import uploadImage from 'assets/img/trial-tour-data-upload.svg'
import themesImage from 'assets/img/trial-tour-themes.svg'
import WidgetPreview from './WidgetPreview.vue'
import { useTrialTour } from './trial-tour'
import TourModal from './modals/TourModal.vue'
import Modal from 'components/Modal.vue'
import TopBar from './TopBar.vue'
import QueryAPI, { ThemeGroup } from 'src/api/query'
import { number } from 'src/utils/formatters'
import Util from 'src/utils/general'
import { Analytics } from 'src/analytics'
import { dateRangeToFilter } from 'src/utils/dates'
import { LocationQueryValue, useRoute } from 'vue-router'

const EXPORT_LIMIT = 100000

export default defineComponent({
  name: 'TrialWorkbench',
  components: {
    FilterBar,
    BfButton,
    BfSpinner,
    Icon,
    WidgetPreview,
    TourModal,
    Modal,
    TopBar,
  },
  props: {
    dashboardId: { type: Number, required: true },
    drilldown: {
      type: [String, Boolean] as PropType<Drilldown>,
      default: false,
    },
    drilldownId: {
      type: String as PropType<string | null>,
      default: null,
    },
    trialMode: {
      type: Boolean,
      default: false,
    },
    zoomWidgetKey: {
      type: String as PropType<string | null>,
      default: null,
    },
    viewerMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:theme-group-tree'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()

    const analytics = inject<Analytics>('analytics')

    // Getters
    const currentDashboard = computed(() => store.getters.currentDashboard as Dashboard)
    const savedQueries = computed(() => store.getters.savedQueries as SavedQuery[])
    const featureFlags = computed(() => store.getters.featureFlags as Record<string, boolean>)
    const expandedSavedQueries = computed(() => store.getters.expandedSavedQueries as SavedQuery[])
    const expandedThemeGroups = computed(() => store.getters.expandedThemeGroups as ExpandedGroup[])
    const dateFields = computed(() => store.getters.dateFields as SchemaColumn[])
    const dashboardWidgetConfig = computed(() => store.getters.dashboardWidgetConfig as DashboardConfig['widgets'])
    const defaultDateField = computed(() => store.getters.defaultDateField as string)
    const dashboardDateRange = computed(() => store.getters.dashboardDateRange as DateRangeConfig)
    const themeGroups = computed(() => store.getters['themeGroups'] as Group[])

    // Providers
    const themeNameMap = computed(() => {
      const queries = savedQueries.value
      return queries.reduce(
        (p, c) => {
          p[c.id] = c.name
          return p
        },
        {} as Record<number, string>,
      )
    })
    provide('themeNameMap', themeNameMap)

    const themeGroupNameMap = computed(() => {
      const map: Record<number, string> = {
        [-1]: 'Ungrouped themes',
      }
      for (const c of themeGroups.value) {
        map[+c.id] = c.name
      }
      return map
    })
    provide('themeGroupNameMap', themeGroupNameMap)

    // Component refs
    const scrollRef = ref<HTMLDivElement>()
    const overviewRef = ref<HTMLDivElement>()
    const widgetWrapperRefs = ref<HTMLDivElement[]>([])
    const widgetRefs = ref<WidgetInstance[]>([])

    // Public State
    const speakerField = ref<string>('')
    const showGroupLabels = ref<boolean>(true)
    const compareMode = ref<boolean>(false)
    const showDataExportLimitModal = ref<boolean>(false)
    const themeGroupTree = ref<GroupOrTheme[]>([])
    const drilldownType = toRef(props, 'drilldown')
    const isLoading = ref(true)
    const hasErrored = ref(false)
    const isLoadingCoverage = ref(true)
    const filterRows = ref<QueryRow[]>([])
    const compareFilterRows = ref<QueryRow[]>([])
    const fetchedData = reactive<FetchedData>({})
    const configLoaded = ref(false)
    const coverageStats = ref<CoverageStats>({
      records: 0,
      totalRecords: 0,
      verbatims: 0,
      totalVerbatims: 0,
    })

    const dashboardOptions = computed<DashboardOptions>(() => {
      return {
        showGroupLabels: showGroupLabels.value,
      }
    })

    provide('featureFlags', featureFlags)
    provide('dashboardOptions', dashboardOptions)
    provide('showGroupLabels', showGroupLabels)

    const baseQuery = computed<QueryType>(() => {
      const query = drilldownQuery.value?.query_value
      return {
        type: 'match_all',
        includes: [{ type: 'all_data' }, query as QueryType].filter(Boolean),
      }
    })

    const dashboardDateRangeFilters = computed(() => {
      return dateRangeToFilter(
        dashboardDateRange.value?.dateField ?? '',
        dashboardDateRange.value?.type ?? DateRangeTypeEnum.ALL_TIME,
        dashboardDateRange.value?.dateFrom ?? '',
        dashboardDateRange.value?.dateTo ?? '',
      )
    })

    const intersectConcepts = computed(() => {
      const concepts = ([] as LocationQueryValue[]).concat(route.query.intersect)
      return concepts.reduce((arr, item) => {
        if (item) return arr.concat(item)
        return arr
      }, [] as string[])
    })

    const conceptIncludes = computed(() => {
      return intersectConcepts.value.map((c: string) => ({
        type: 'match_any',
        includes: [{ type: 'text', value: c }],
      }))
    })

    const drilldownQuery = computed<SavedQuery | null>(() => {
      let query: SavedQuery | ExpandedGroup | null = null
      if (drilldownType.value === 'theme') {
        const id = Number(props.drilldownId)
        query = expandedSavedQueries.value.find((q) => q.id === id) ?? null
      }
      if (drilldownType.value === 'theme_group') {
        const id = Number(props.drilldownId)
        query = expandedThemeGroups.value.find((q) => q.id === id) ?? null
      }
      if (drilldownType.value === 'segment') {
        const [fieldName, segment] = props.drilldownId?.split(':') ?? []
        query = {
          id: -1,
          group: false,
          name: `${fieldName}: ${segment}`,
          query_value: {
            type: 'match_all',
            includes: [
              {
                type: 'segment',
                field: fieldName,
                value: segment,
                operator: '=',
              },
            ],
            excludes: [],
          },
        }
      }
      if (drilldownType.value === 'concept') {
        const concept = props.drilldownId ?? ''
        query = {
          id: -1,
          group: false,
          name: concept,
          query_value: {
            type: 'match_all',
            includes: [
              {
                type: 'match_any',
                includes: [
                  {
                    type: 'text',
                    value: concept,
                  },
                ],
              },
            ],
            excludes: [],
          },
        }
      }
      if (query) {
        return {
          ...query,
          query_value: {
            type: 'match_all',
            includes: [query.query_value, ...conceptIncludes.value],
          },
        } as SavedQuery
      }
      return null
    })

    const workbenchMode = computed<WorkbenchMode>(() => {
      if (drilldownQuery.value) {
        return props.trialMode ? WorkbenchMode.TrialDrilldown : WorkbenchMode.Drilldown
      }
      return props.trialMode ? WorkbenchMode.TrialOverview : WorkbenchMode.Overview
    })

    const validFilterRows = computed(() => {
      const filters = [...dashboardDateRangeFilters.value]
      if (filterRows.value.length && !isQueryValid(filterRows.value)) return filters
      filters.push(...QueryUtils.convertBotanicQueriesToDashboardFilters(filterRows.value))
      return filters
    })

    // Sidebar overview marker
    const { topWidgetId } = useTopVisibleWidget(scrollRef, widgetWrapperRefs)
    const overviewMarkerOffset = computed<number>(() => {
      if (!topWidgetId.value || !overviewRef.value) return 0
      const topId = topWidgetId.value
      const topIndex = widgetWrapperRefs.value.findIndex((el) => el.id === topId)
      if (topIndex === -1) return 0
      const linkRef = overviewRef.value.children[topIndex] as HTMLDivElement
      const containerTop = overviewRef.value.getBoundingClientRect().top
      const widgetTop = linkRef.getBoundingClientRect().top
      return widgetTop - containerTop
    })

    // Add or remove filter rows by field and segment
    const toggleFilter = (field: string, segment: string) => {
      const filter = [{ field, segment }]

      if (field === 'All Segments') {
        const values = segment.split(':')
        filter[0].field = values[0].trim()
        filter[0].segment = values[1].trim()
      }

      const rows = applyToggledSegmentsToQueryRows(filter, filterRows.value)
      filterRows.value = rows

      // Track segment filter toggle
      if (analytics) {
        analytics.track.dashboard.toggleSegmentFilter(currentDashboard.value.id, filter[0].field, filter[0].segment)
      }
    }

    // Widget data
    const { widgetList } = useWidgetList(workbenchMode, drilldownType, dashboardWidgetConfig)
    const { widgetProps } = useWidgetProps(
      isLoading,
      drilldownType,
      baseQuery,
      drilldownQuery,
      validFilterRows,
      fetchedData,
      coverageStats,
      toRef(null),
      toRef(props, 'zoomWidgetKey'),
    )
    const { widgetEvents } = useWidgetEvents(
      validFilterRows,
      drilldownType,
      drilldownQuery,
      fetchedData,
      toggleFilter,
      props.trialMode,
      props.viewerMode,
    )

    const zoomedWidget = computed(() => {
      if (props.zoomWidgetKey) {
        return widgetList.value.find((widget) => widget.key === props.zoomWidgetKey)
      }
      return null
    })

    const scrollToWidget = (id: string) => {
      const widget = widgetWrapperRefs.value.find((el) => el.id === id)
      if (widget && scrollRef.value) {
        widget.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }

    // Convert coverage float to percentage
    const getCoveragePercent = (numerator: number, denominator: number): number => {
      return Number(Math.round((numerator / Math.max(1, denominator)) * 100).toFixed(2))
    }

    const loadConfig = () => {
      filterRows.value = currentDashboard.value.config?.queryRows ?? []
      compareFilterRows.value = currentDashboard.value.config?.compareQueryRows ?? []
      compareMode.value = currentDashboard.value.config?.compareMode ?? false
      showGroupLabels.value = currentDashboard.value.config?.showGroupLabels ?? true
      configLoaded.value = true
    }

    const loadWorkbench = async () => {
      isLoading.value = true
      try {
        await store.dispatch(LOAD_DASHBOARD, {
          dashboardId: props.dashboardId,
          rethrowErrors: true,
          loadThemes: false,
          loadConfig: true,
          isViewer: true,
        })
        await store.dispatch(LOAD_ANALYSIS, {
          analysisId: currentDashboard.value.analysis.id,
          projectId: currentDashboard.value.project.id,
        })
        trackViewEvent()
        const [, groups] = await Promise.all([
          await updateCoverage(),
          await ThemeGroup.list(currentDashboard.value.project.id, currentDashboard.value.analysis.id),
        ])
        themeGroupTree.value = groups.group_tree
        emit('update:theme-group-tree', groups.group_tree)

        // Don't reset config if it's already loaded to avoid overwriting staged changes
        if (!configLoaded.value) {
          loadConfig()
        }
      } catch (error) {
        console.error(error)
        hasErrored.value = true
      } finally {
        isLoading.value = false
      }
    }

    // Call fetchData method on all widgets
    const refreshWidgets = () => {
      // Delay until next tick to ensure filters etc are updated
      setTimeout(() => {
        widgetRefs.value.forEach((widget, i) => {
          if ('fetchData' in widget) {
            widget.fetchData()
          } else {
            console.error(`${widgetList.value[i].key} widget missing fetchData method.`)
          }
        })
      }, 0)
    }

    const updateCoverage = async () => {
      isLoadingCoverage.value = true
      coverageStats.value = await fetchCoverageStats(
        baseQuery.value,
        validFilterRows.value,
        savedQueries.value,
        dateFields.value.map((field) => field.name),
        currentDashboard.value,
      )
      isLoadingCoverage.value = false
    }

    watch(validFilterRows, (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        updateCoverage()
        refreshWidgets()

        // Track filter changes
        if (analytics) {
          const dateField = newVal.some((row) => row.field && dateFields.value.some((df) => df.name === row.field))
          analytics.track.dashboard.applySegmentFilter(
            currentDashboard.value.id,
            props.viewerMode,
            Array.from(
              new Set(
                newVal
                  .filter((row) => !dateFields.value.some((df) => df.name === row.field))
                  .map((row) => row.field || ''),
              ),
            ),
            newVal.filter((row) => !dateFields.value.some((df) => df.name === row.field)).length,
            dateField,
            true,
          )
        }
      }
    })

    watch(
      () => [props.drilldownId, props.dashboardId],
      (newVal, oldVal) => {
        if (!isEqual(newVal, oldVal)) {
          loadWorkbench()
        }
      },
      {
        deep: true,
      },
    )

    watch(
      drilldownQuery,
      (newVal, oldVal) => {
        if (!isEqual(newVal, oldVal)) {
          loadWorkbench()
        }
      },
      {
        deep: true,
      },
    )

    watch(drilldownType, (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        loadWorkbench()
      }
    })

    // Free trial tour
    const startDialogVisible = ref(false)
    const tourDialogVisible = ref(false)
    const tour = useTrialTour(props.dashboardId, widgetRefs)
    // Show the dialogue that is really step 1 of the tour
    const startTourDialog = () => {
      startDialogVisible.value = false
      tourDialogVisible.value = true
    }
    // Actually start the tour by invoking driver.js
    const startTour = () => {
      tourDialogVisible.value = false
      tour.start()
    }
    // Function to start the tour, if required.
    // Force attribute will skip checking if the tour has already run.
    const initTour = (force: boolean = false) => {
      const storageKey = 'kapiche-trial-tour'
      if (force === false) {
        // Check if the tour has run already
        if (localStorage.getItem(storageKey) !== null) {
          return
        }
      }

      // Is this the first time running?
      if (localStorage.getItem(storageKey) === null) {
        // Show modal
        startDialogVisible.value = true
      } else {
        // Just start the tour
        tour.start()
      }
      localStorage.setItem(storageKey, 'executed')
    }

    const drilldownConcepts = computed<string[]>(() => {
      // TODO:
      return []
    })

    const backRouteName = computed(() => {
      if (props.trialMode) return 'trial-results'
      if (props.viewerMode) return 'viewer-workbench'
      return 'analysis-workbench'
    })

    const exportCSV = async () => {
      if (coverageStats.value.records > EXPORT_LIMIT) {
        showDataExportLimitModal.value = true
        return
      }

      try {
        let query
        let exportName = 'overview'
        const dateFieldNames = dateFields.value.map((field) => field.name)
        if (drilldownType.value && drilldownQuery.value) {
          query = drilldownQuery.value.query_value
          query = mergeDashboardFiltersWithBotanicQuery(query, validFilterRows.value, dateFieldNames)
          exportName = ['theme', 'theme_group'].includes(drilldownType.value) ? 'theme' : 'concept'
        } else {
          query = QueryUtils.convertDashboardFiltersToBotanicQueries(validFilterRows.value, dateFieldNames)
        }
        const res = await QueryAPI.runQueryExport(
          currentDashboard.value.project.id,
          currentDashboard.value.analysis.id,
          query,
          savedQueries.value,
        )
        stringify([res.headers].concat(res.rows), (_, csvString) => {
          Util.downloadCsv(csvString, `dashboard-${exportName}-result`)
        })

        if (drilldownType.value === 'theme') {
          analytics?.track.analysis.downloadExport('Dashboard Query Results', 'CSV', {
            queryName: [drilldownQuery.value?.name, ...drilldownConcepts.value].join(' & '),
            queryValue: JSON.stringify(drilldownQuery.value),
            concepts: drilldownConcepts.value,
            workbench: true,
          })
        }
        if (drilldownType.value === 'concept') {
          analytics?.track.analysis.downloadExport('Dashboard Concept Results', 'CSV', {
            concepts: drilldownConcepts.value.join(' & '),
            workbench: true,
          })
        }
      } catch {
        console.error('Error exporting CSV')
      }
    }

    const exportPPT = async () => {
      const pptx = new PptxGenJS()

      for (const widget of widgetRefs.value) {
        if ('makePptSlide' in widget) {
          await widget.makePptSlide?.(pptx)
        }
      }

      pptx.writeFile({
        fileName: `${currentDashboard.value.name}.pptx`,
      })

      analytics?.track.analysis.downloadExport('Export Dashboard', 'PPT', {
        dashboardName: currentDashboard.value.name,
        workbench: true,
      })
    }

    watch(route, (newVal) => {
      if (newVal?.name && newVal.name.toString().toLowerCase().endsWith('zoom')) {
        analytics?.track.dashboard.zoomWidget(
          currentDashboard.value.id,
          props.viewerMode,
          newVal.params.zoomWidgetKey as string,
          true,
        )
      }
    })

    const discardChanges = () => {
      filterRows.value = currentDashboard.value.config?.queryRows ?? []
      compareFilterRows.value = currentDashboard.value.config?.compareQueryRows ?? []
      speakerField.value = currentDashboard.value.speaker_field
      compareMode.value = currentDashboard.value.config?.compareMode ?? false
    }

    const resetAllWidgetConfig = () => {
      const widgets: DashboardConfig['widgets'] = cloneDeep(dashboardWidgetConfig.value)

      ;[...widgets.overview, ...widgets.drilldown].forEach((widget) => {
        widget.visible = true
        widget.options = {}
      })

      store.commit(SET_WIDGET_CONFIG, { widgets })

      if (analytics) {
        analytics.track.dashboard.customization.widgetConfigApplied(currentDashboard.value.id, widgets)
      }
    }

    const resetWorkbench = () => {
      resetAllWidgetConfig()
      store.commit(SAVE_DASHBOARD_DATE_RANGE, {
        dateRange: {
          type: DateRangeTypeEnum.ALL_TIME,
          dateField: defaultDateField.value,
          dateTo: '',
          dateFrom: '',
        },
      })
      const selectedQueries = processQueries(currentDashboard.value.queries)
      store.commit(SET_DASHBOARD_QUERIES, selectedQueries)
      filterRows.value = []
      compareFilterRows.value = []

      if (analytics) {
        const extraAttrs = { action: 'reset', source: 'workbench' } as Record<string, unknown>
        analytics.track.dashboard.view(
          String(currentDashboard.value.id),
          savedQueries.value.length,
          props.viewerMode,
          'workbench',
          extraAttrs,
        )
      }
    }

    const trackViewEvent = () => {
      // Track the view event after the workbench is loaded
      if (analytics) {
        if (drilldownType.value === 'theme') {
          analytics.track.dashboard.viewTheme(
            currentDashboard.value.id,
            props.viewerMode,
            Number(props.drilldownId),
            drilldownQuery.value?.name || '',
            true,
          )
        } else if (drilldownType.value === 'theme_group') {
          analytics.track.dashboard.viewThemeGroup(
            currentDashboard.value.id,
            props.viewerMode,
            Number(props.drilldownId),
            drilldownQuery.value?.name || '',
            true,
          )
        } else if (drilldownType.value === 'concept') {
          analytics.track.dashboard.viewConcept(
            currentDashboard.value.id,
            props.viewerMode,
            props.drilldownId || '',
            true,
          )
        } else {
          // Overview
          const extraAttrs = { workbench: true } as Record<string, unknown>
          analytics.track.dashboard.view(
            String(currentDashboard.value.id),
            savedQueries.value.length,
            props.viewerMode,
            'workbench',
            extraAttrs,
          )
        }
      }
    }

    onMounted(async () => {
      if (props.trialMode) {
        initTour()
        emitter.on('startTour', startTour)
      }
      await loadWorkbench()
    })

    onBeforeUnmount(() => {
      emitter.off('startTour', startTour)
    })

    return {
      uploadImage,
      themesImage,
      isLoading,
      isLoadingCoverage,
      currentDashboard,
      filterRows,
      compareFilterRows,
      location: QueryLocation.TrialWorkbench,
      coverageStats,
      startDialogVisible,
      tourDialogVisible,
      startTourDialog,
      startTour,
      getCoveragePercent,
      widgetList,
      WidgetIcons,
      widgetProps,
      widgetEvents,
      scrollRef,
      widgetWrapperRefs,
      scrollToWidget,
      overviewRef,
      overviewMarkerOffset,
      topWidgetId,
      hasErrored,
      widgetRefs,
      drilldownQuery,
      tour,
      themeGroupTree,
      showGroupLabels,
      EXPORT_LIMIT,
      showDataExportLimitModal,
      number,
      exportCSV,
      exportPPT,
      speakerField,
      compareMode,
      discardChanges,
      resetWorkbench,
      zoomedWidget,
      backRouteName,
      intersectConcepts,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.wrapper {
  height: 100vh;
  padding: 30px 30px 0;
  color: $text-black;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.coverage-stats {
  font-size: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  :deep(.bf-spinner-container) {
    height: 20px;
    .bf-spinner {
      width: 13px !important;
      height: 13px !important;
      border-width: 2px !important;
      margin-right: 8px;
    }
  }

  span {
    margin: 0 10px;
    font-size: 8px;
    vertical-align: top;
  }
}

.content-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;

  > .widget {
    width: 100%;
  }

  .widget-overview {
    margin-right: 30px;
    position: relative;
    white-space: nowrap;
    user-select: none;

    > div:first-child {
      border-left: 2px solid $grey-mid-light;
      padding-left: 20px;
    }

    .marker {
      position: absolute;
      left: 0;
      width: 4px;
      height: 25px;
      background-color: $blue;
      transition: top 0.3s;
    }

    .widget-link {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }

      &:hover {
        span {
          color: $text-black;
        }
      }

      .overview-icon {
        $size: 22px;
        width: $size;
        height: $size;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 13px;
        position: relative;
        background-color: $grey-mid;
      }

      &.active {
        .overview-icon {
          background-color: transparent;
        }
        span {
          color: $text-black;
        }
      }

      span {
        font-size: 16px;
        color: $grey-mid;
      }
    }

    .cta {
      margin-top: 20px;
      background-color: $white;
      border: 1px $green solid;
      border-radius: 5px;
      padding: 20px;
      text-align: center;
      line-height: 24px;
      font-size: 16px;
      max-width: 300px;
      box-shadow:
        0px 0px 3px 0px rgba(0, 0, 0, 0.03),
        0px 3px 5px 0px rgba(0, 0, 0, 0.05),
        0px 1.7px 2.1px 0px rgba(0, 0, 0, 0.03),
        0px 0.8px 1.1px 0px rgba(0, 0, 0, 0.03),
        0px 0.5px 0.6px 0px rgba(0, 0, 0, 0.02),
        0px 0.3px 0.3px 0px rgba(0, 0, 0, 0.01),
        0px 0.1px 0.1px 0px rgba(0, 0, 0, 0.01);
      white-space: wrap;

      a {
        color: $green;
        font-weight: bold;
      }
    }
  }

  .widget-wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;

    .widget-scroll {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-bottom: 20px;
    }

    .drill-down-title {
      font-size: 30px;
      margin-bottom: 20px;
      padding-top: 2px;
      line-height: 30px;
      display: flex;
      align-items: center;

      > span {
        display: flex;
        align-items: center;
        > span {
          color: $text-grey;
          font-size: 18px;
          margin: 4px 10px 0;
        }
      }
    }

    .widget {
      margin-bottom: 30px;
      max-width: 1200px;
      width: 100%;
    }

    > p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: $text-grey;

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
  }
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info-bar {
  margin: 15px 0 20px;
  display: flex;

  > div:nth-child(1) {
    flex: 1;
    margin-right: 20px;
  }

  > div:nth-child(2) {
    overflow: hidden;
  }
}

.back-link {
  color: $blue;
  font-size: 12px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  font-weight: bold;

  :deep(.icon-wrapper) {
    position: relative;
    top: 0.5px;
  }
}

.dialog-footer {
  padding-bottom: 15px;
}

.dialog {
  text-align: center;
  padding: 40px 40px 55px 40px;

  h2 {
    font-size: 38px;
    color: $text-black;
  }

  p {
    font-size: 16px;
    padding: 20px;
    text-align: center;
  }

  .footer {
    p {
      padding-bottom: 0;
      font-weight: bold;
    }
  }
}

.start-body {
  width: 80%;
  text-align: left;
  margin: auto;
  > .block {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }
  h4 {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 22px;
    text-transform: uppercase;
  }
  p {
    text-align: left;
    margin-bottom: 0;
    line-height: 24px;
    padding: 0px;
    padding-bottom: 10px;
  }
}
</style>

<template>
  <modal :visible="visible" @close="close">
    <template #header>
      <div>Set Text Fields as Read Only</div>
    </template>
    <template #content>
      <div class="content">
        <p class="help-text">
          Read Only text fields are stored but not indexed in the project. This means that the text is this field will
          not be analysed. They wouldn't get sentiment tags, or aitopics.
        </p>
        <div class="multiselect-container">
          <p class="multiselect-label">Choose one or more fields</p>
          <el-select
            v-if="options.length"
            :model-value="selected"
            class="options-list"
            multiple
            filterable
            placeholder='Choose fields e.g. "Text Response"'
            type="primary"
            no-match-text="No matching fields"
            @update:model-value="selected = $event"
          >
            <el-option v-for="o in options" :key="o.index" :label="o.name" :value="o.name" type="primary" />
          </el-select>
        </div>
      </div>
    </template>
    <template #buttons>
      <div class="footer">
        <bf-button color="grey" size="large" @click="close"> Cancel </bf-button>
        <bf-button color="blue" size="large" @click="done"> Done </bf-button>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { clone } from 'lodash'
import { BfButton } from 'components/Butterfly'
import Modal from 'components/Modal.vue'
import { SchemaColumn } from 'types/SchemaTypes'

export default defineComponent({
  components: {
    Modal,
    BfButton,
  },
  props: {
    visible: { type: Boolean, default: false },
    schema: { type: Array as PropType<Array<SchemaColumn>>, required: true },
  },
  data() {
    return {
      selected: [] as Array<string>,
    }
  },
  computed: {
    options(): SchemaColumn[] {
      return this.schema.filter((col) => col.type === 1)
    },
  },
  watch: {
    currentFields() {
      this.resetState()
    },
  },
  methods: {
    resetState() {
      this.selected = this.currentFields
    },
    close() {
      this.resetState()
      this.$emit('close')
    },
    done() {
      this.$emit('values-changed', clone(this.selected))
      this.close()
    },
  },
})
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
.title, .help-text
  width: 100%
  margin-bottom: 10px
  line-height: 1.5
.multiselect-container
  width: 100%
  max-width: 500px
  margin: 10px auto
  .options-list
    width: 100%
  .multiselect-label
    font-weight: bold
    text-align: left
    margin-bottom: 5px
</style>

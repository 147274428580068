<template>
  <div class="dot-container">
    <div class="dots-grid">
      <div v-for="(group, i) of partition(visibleDots, columns)" :key="i">
        <div
          v-for="dot in group"
          :key="dot.id"
          :class="['dot-item', { hidden: dot.visible === false }]"
          @mouseover="$emit('hover-dot', dotIndex[dot.id])"
          @mouseleave="$emit('hover-leave')"
          @click="$emit('click-dot', dotIndex[dot.id])"
        >
          <span v-if="showNumbers" class="number">{{ dotIndex[dot.id] + 1 }}</span>
          <span class="dot" :style="{ backgroundColor: dot.color }"></span>
          <div class="label">
            <span :title="dot.label">
              <slot name="label" :dot="dot" />
              <span v-if="!$slots.label">{{ dot.label }}</span>
            </span>
            <span v-if="dot.groupLabel" :title="dot.groupLabel">[{{ dot.groupLabel }}]</span>
          </div>
        </div>
      </div>
    </div>
    <button v-if="showAllClick && totalDots > visibleDots.length" @click="showAllClick">
      Show {{ totalDots - visibleDots.length }} more
    </button>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

interface Dot {
  id: number | string
  color?: string
  label: string
  groupLabel?: string
  visible?: boolean
}

export default defineComponent({
  name: 'WidgetLegend',
  props: {
    visibleDots: {
      type: Array as PropType<Dot[]>,
      required: true,
    },
    totalDots: {
      type: Number,
      default: 0,
    },
    showNumbers: {
      type: Boolean,
      default: true,
    },
    showAllClick: {
      type: Function as PropType<() => void>,
      default: null,
    },
    columns: {
      type: Number,
      default: 2,
    },
  },
  emits: ['hover-dot', 'hover-leave', 'click-dot'],
  setup(props) {
    // Split array into n parts
    const partition = (items: Dot[], n: number) => {
      const perPart = Math.ceil(items.length / n)
      return Array.from({ length: n }, (_, i) => items.slice(i * perPart, (i + 1) * perPart)).filter((x) => x.length)
    }

    // Map of dot id to index in visibleDots
    const dotIndex = computed(() => {
      return props.visibleDots.reduce(
        (acc, dot) => {
          acc[dot.id] = props.visibleDots.indexOf(dot)
          return acc
        },
        {} as Record<string, number>,
      )
    })

    return {
      partition,
      dotIndex,
    }
  },
})
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.dot-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px 0 20px;
}

.zoomed {
  .dot-container {
    padding: 0;
  }
}

.dots-grid {
  display: flex;
  flex-direction: row;
  width: 100%;
  > div {
    flex: 1;
    min-width: 0;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.dot-item {
  display: flex;
  align-items: center;
  min-width: 0;
  margin-top: 5px;
  user-select: none;
  cursor: default;

  &.hidden {
    opacity: 0.5;
  }

  .number {
    color: $subdued;
    font-weight: bold;
    font-size: 13px;
  }
  .dot {
    $size: 10px;
    width: $size;
    min-width: $size;
    height: $size;
    border-radius: 50%;
    margin: 0 8px;
  }
  .label {
    font-size: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:nth-child(1) {
        flex-shrink: 0;
      }
      &:nth-child(2) {
        margin-top: 1px;
        color: #aaa;
        margin-left: 6px;
      }
    }
  }
}
button {
  margin-top: 20px;
  padding: 0;
  background-color: none;
  color: $blue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}
</style>

<template>
  <div class="legend">
    <sort-controls
      :sort-options="sortOptions"
      :selected-sort-option="selectedSortOption"
      :visible-count="visibleCount"
      @update-sort-method="$emit('update-sort-method', $event)"
      @update-visible-count="$emit('update-visible-count', $event)"
    />
    <widget-legend
      :visible-dots="visibleDots"
      :total-dots="totalDots"
      :show-all-click="showAllClick"
      :columns="isZoomed ? 1 : 2"
      @hover-dot="$emit('hover-dot', $event)"
      @hover-leave="$emit('hover-leave')"
      @click-dot="$emit('click-dot', $event)"
    >
      <template #label="{ dot }">
        <span class="label-tag" :data-dot-id="dot.id">{{ dot.label }}</span>
      </template>
    </widget-legend>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import SortControls from 'components/DataWidgets/SortControls.vue'
import WidgetLegend from 'components/DataWidgets/WidgetLegend.vue'
import { SortMethod } from './QuadrantWidget.utils'

export default defineComponent({
  components: {
    SortControls,
    WidgetLegend,
  },
  props: {
    sortOptions: {
      type: Array as PropType<[string, SortMethod][]>,
      required: true,
    },
    selectedSortOption: {
      type: String as PropType<SortMethod>,
      required: true,
    },
    visibleCount: {
      type: Number,
      required: true,
    },
    totalDots: {
      type: Number,
      required: true,
    },
    isZoomed: {
      type: Boolean,
      required: false,
      default: false,
    },
    visibleDots: {
      type: Array as PropType<
        Array<{
          id: string
          color: string
          label: string
          groupLabel: string
        }>
      >,
      required: true,
    },
    showAllClick: {
      type: Function as PropType<() => void>,
      required: false,
      default: undefined,
    },
  },
  emits: ['update-sort-method', 'update-visible-count', 'hover-dot', 'hover-leave', 'click-dot'],
  setup() {
    return {}
  },
})
</script>

<style lang="scss" scoped>
.legend {
  width: 100%;
  padding: 0 10px;
}

.label-tag {
  cursor: pointer;
}
</style>

<template>
  <div
    ref="quadrantContainer"
    class="svgContainer quadrant"
    @mouseover="mouseOver = true"
    @mouseout="mouseOver = false"
  >
    <interaction-menu v-for="dot in dots" :key="`menu-${dot.id}`" :get-parent-element="() => getDotElement(dot.id)">
      <slot name="interaction-menu" :dot="dot" />
    </interaction-menu>
    <svg ref="quadrant-chart" :width="width" :height="height">
      <rect
        :x="xScale(xAxis.min)"
        :y="yScale(yAxis.max)"
        :width="chartWidth / 2"
        :height="chartHeight / 2"
        :fill="quadColors.topLeft"
      ></rect>
      <rect
        :x="chartWidth / 2 + xScale(xAxis.min)"
        :y="chartHeight / 2 + padding.top"
        :width="chartWidth / 2"
        :height="chartHeight / 2"
        :fill="quadColors.bottomRight"
      ></rect>
      <rect
        :x="xScale(xAxis.min)"
        :y="chartHeight / 2 + padding.top"
        :width="chartWidth / 2"
        :height="chartHeight / 2"
        :fill="quadColors.bottomLeft"
      ></rect>
      <rect
        :x="chartWidth / 2 + xScale(xAxis.min)"
        :y="yScale(yAxis.max)"
        :width="chartWidth / 2"
        :height="chartHeight / 2"
        :fill="quadColors.topRight"
      ></rect>

      <text
        ref="xLabel"
        v-truncate.svg
        class="axis-label"
        :y="height - (gutters.top - 7)"
        :x="(xScale(xAxis.max) + gutters.left) / 2"
        text-anchor="middle"
      >
        {{ xAxis.label }}
      </text>

      <text
        ref="yLabel"
        v-truncate.svg
        class="axis-label"
        :y="height / 2 - padding.top"
        :x="xScale(xAxis.min) - padding.left * 1.9"
        :transform="`rotate(-90 ${xScale(xAxis.min) - padding.left * 1.9} ${height / 2 - padding.top})`"
        text-anchor="middle"
      >
        {{ yAxis.label }}
      </text>

      <g v-for="(vertical, index) in verticals" :key="`vert_${index}_${vertical.value}`">
        <line
          v-if="vertical.showLine"
          class="baseline-line"
          :x1="xScale(vertical.value)"
          :y1="0 + gutters.top"
          :x2="xScale(vertical.value)"
          :y2="height - (gutters.bottom + gutters.top)"
          :stroke="vertical.color"
        />
        <text
          :ref="`text_${vertical.value}_${vertical.label}`"
          class="baseline-line"
          :x="verticalXPosition(vertical.value, vertical.label)"
          :y="0 + gutters.top - 10"
          text-anchor="middle"
          :fill="vertical.color"
        >
          {{ vertical.label }}
        </text>
      </g>
      <g ref="dotsContainer" @mousemove="updateCoordinates">
        <g v-for="(dot, index) in dots" :key="dot.id" :data-dot-id="dot.id" @click="$emit('menu-clicked')">
          <circle
            :class="dotClass(dot)"
            :cx="xScale(dot.x)"
            :cy="yScale(dot.y)"
            :r="dot.size + 1"
            :fill="dot.color"
            @mouseover="handleMouseOver(dot.id)"
            @mouseout="handleMouseOut()"
          />
          <text
            v-truncate.svg="30"
            :class="[
              'text',
              dotClass(dot),
              {
                start: labelSide(dot) === 'start',
              },
            ]"
            :transform="`translate(${labelSide(dot) === 'start' ? xScale(Number(dot.x)) + dot.size + 5 : xScale(Number(dot.x)) - dot.size - 5}, ${yScale(dot.y) + dot.size})`"
            :text-anchor="labelSide(dot)"
            @mouseover="handleMouseOver(dot.id)"
            @mouseout="handleMouseOut()"
          >
            <tspan v-truncate="30">{{ dot.label }}</tspan>
            <tspan v-if="dotLabels[dot.id]" class="group-tag" :x="-2" :dy="20">
              [
              <tspan v-truncate.svg="28">{{ dotLabels[dot.id] }}</tspan>
              ]
            </tspan>
          </text>
        </g>
      </g>
    </svg>
    <floating-panel :visible="mouseOver && !!hoveredDot" :y="hoverY" :x="hoverX" :bound="$refs['quadrantContainer']">
      <slot name="tool-tip"></slot>
    </floating-panel>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import InteractionMenu from 'components/widgets/InteractionMenu.vue'
import { scaleLinear, axisBottom, axisLeft, select, selectAll } from 'd3'
import { QuadrantChartDot } from 'src/types/components/Charts.types'

export default defineComponent({
  components: {
    FloatingPanel,
    InteractionMenu,
  },
  props: {
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    quadColors: {
      type: Object,
      required: false,
      default: () => ({ topLeft: 'red', topRight: 'green', bottomLeft: 'yellow', bottomRight: 'blue' }),
    },
    dots: { type: Array as PropType<QuadrantChartDot[]>, required: false, default: () => [] },
    xAxis: { type: Object, required: true },
    yAxis: { type: Object, required: true },
    verticals: { type: Array, required: false, default: () => [] },
    dotLabels: {
      type: Object as PropType<Record<number, string>>,
      required: false,
      default: () => ({}),
    },
    hoveredDot: { type: String, required: false, default: null },
  },
  data() {
    return {
      quadrantChart: null,
      gutters: { top: 30, bottom: 60, left: 100, right: 0 },
      padding: { top: 30, bottom: 30, left: 30, right: 0 }, // Padding between the chart and the axis
      hoverX: 0,
      hoverY: 0,
      mouseOver: false,
    }
  },
  computed: {
    xScale() {
      return scaleLinear()
        .domain([this.xAxis.min, this.xAxis.max])
        .range([this.gutters.left, this.width - (this.gutters.right + 40)])
    },
    yScale() {
      return scaleLinear()
        .domain([this.yAxis.min, this.yAxis.max])
        .range([this.height - (this.gutters.bottom + this.padding.bottom), this.gutters.top])
    },
    chartWidth() {
      return Math.round(Math.abs(this.xScale(this.xAxis.min) - this.xScale(this.xAxis.max)))
    },
    chartHeight() {
      return Math.round(Math.abs(this.yScale(this.yAxis.min) - this.yScale(this.yAxis.max)))
    },
  },
  watch: {
    height() {
      this.drawAxis()
    },
    width() {
      this.drawAxis()
    },
    xAxis() {
      this.drawAxis()
    },
    yAxis() {
      this.drawAxis()
    },
  },
  mounted() {
    this.quadrantChart = select(this.$el.querySelector('div.svgContainer.quadrant > svg'))
    this.drawAxis()
  },
  methods: {
    updateCoordinates(event: MouseEvent) {
      this.hoverX = event.clientX
      this.hoverY = event.clientY
    },
    getDotElement(id: string) {
      if (!this.$refs.dotsContainer) return null
      let maybeItems = this.$refs.quadrantContainer?.parentElement?.querySelectorAll(`[data-dot-id="${id}"]`)
      return Array.from(maybeItems ?? [])
    },
    updateSeriesLabels() {
      if (!this.$refs.dotsContainer) return

      const dotElements = this.$refs.dotsContainer.querySelectorAll('[data-dot-id]')
      dotElements.forEach((dotEl) => {
        const label = dotEl.querySelector('.label-tag')
        if (label) {
          const text = dotEl.querySelector('.text')
          const start = text.classList.contains('start')
          if (!start) {
            const point = dotEl.querySelector('.point')
            if (point) {
              const x = Number(point.getAttribute('cx'))
              const { width } = label.querySelector('span').getBoundingClientRect()
              label.setAttribute('x', x - width - 10)
            }
            return
          }
          const x = Number(text.getAttribute('x'))
          label.setAttribute('x', x)
        }
      })
    },
    verticalXPosition(value, label) {
      let labelLength

      if (this.$refs[`text_${value}_${label}`]) {
        try {
          labelLength = this.$refs[`text_${value}_${label}`][0].getBBox().width
        } catch (e) {
          labelLength = label.length * 8
        }
      }

      const halfLength = labelLength / 2
      const xPos = this.xScale(value)
      const min = this.xScale(this.xAxis.min)
      const max = this.xScale(this.xAxis.max)

      // too left
      if (xPos - halfLength < min) {
        return xPos + (min - (xPos - halfLength))
      }
      // too right
      if (xPos + halfLength > max) {
        return xPos - (xPos + halfLength - max)
      }
      // otherwise
      return this.xScale(value)
    },
    labelSide(dot) {
      if (!dot || !dot.label) return 'start'
      return this.xScale(Number(dot.x)) + dot.label.length * 10 >= this.xScale(this.xAxis.max) ? 'end' : 'start'
    },
    dotClass(dot) {
      if (dot.hidden) return 'hidden'
      if (this.hoveredDot && dot.id !== this.hoveredDot) return 'faded'
      return 'point'
    },
    drawAxis() {
      const axes = selectAll(this.$el.querySelectorAll('.axis-colour'))
      axes.remove()

      let xAxis = axisBottom(this.xScale)
        .tickFormat((val) => (this.xAxis.percent ? `${Number(val).toFixed(2)}%` : Number(val).toFixed(2)))
        .tickSizeInner(-5)
        .tickSizeOuter(5)
        .tickPadding(10)
        .tickValues([this.xAxis.min, (this.xAxis.max + this.xAxis.min) / 2, this.xAxis.max])

      let yAxis = axisLeft(this.yScale)
        .tickFormat((val) => (this.yAxis.percent ? `${Number(val).toFixed(2)}%` : Number(val).toFixed(2)))
        .tickValues([this.yAxis.min, this.yAxis.max])
        .tickSizeInner(-5)
        .tickSizeOuter(5)
        .tickPadding(10)

      // Append our axes
      let xLine = this.quadrantChart
        .append('g')
        .attr('transform', `translate(0, ${this.height - (this.gutters.top + this.padding.top)})`) // we position from top left
        .attr('class', 'axis-colour')
        .style('font-size', 14)
        .call(xAxis)
      let yLine = this.quadrantChart
        .append('g')
        .attr('transform', `translate(${this.gutters.left - this.padding.left}, 0)`)
        .attr('class', 'axis-colour')
        .style('font-size', 14)
        .call(yAxis)
      // Restyle axis colours
      xLine.selectAll('path, g.tick line').style('stroke', '#95a6ac')
      yLine.selectAll('path, g.tick line').style('stroke', '#95a6ac')
      // Explicitly set the font sizes for the axis ticks here: firefox will fall back to 10px otherwise
      xLine.selectAll('path, g.tick text').style('font-size', '14px')
      yLine.selectAll('path, g.tick text').style('font-size', '14px')

      this.updateSeriesLabels()
    },

    handleMouseOver(id: string) {
      this.$emit('hover', id)
    },
    handleMouseOut() {
      this.$emit('hover', null)
    },
  },
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.svgContainer
  width: 100%

svg
  top: 0
  left: 0

  .text
    cursor: default
    font-weight: bold
    color: black
  .point
    cursor: default
    stroke-opacity: 0.6
    fill-opacity: 1
    stroke-width: 1.5
    transition: fill-opacity 0.3s, stroke-opacity 0.3s

  .hidden
    cursor: none
    fill-opacity: 0
    stroke-opacity: 0
  .faded
    cursor: none
    fill-opacity: 0.15
    stroke-opacity: 0.07

  .axis-label
    font-weight: bold

  line.baseline-line
    stroke-width: 2
    stroke-opacity: 0.25
  text.baseline-line
    font-weight: bold

.label-tag
  overflow: visible
</style>

<template>
  <div class="wrapper">
    <div class="theme-builder">
      <div class="sidebar">
        <progress-bar
          :is-loading="isLoading.globalStats"
          :progress="progressCoverage"
          :goal="80"
          :ignored="percent(queryStats.ignored_verbatim_count, queryStats.total_verbatim_count)"
        />
        <div class="content">
          <el-tabs :model-value="activeTab" @update:model-value="activeTab = $event">
            <el-tab-pane label="Concepts" name="concepts">
              <label>
                <toggle-slider-input small :value="hideMappedSidebar" @input="hideMappedSidebar = $event" />
                Hide concepts already in a Theme
              </label>
              <concept-list
                class="tab-content"
                :concepts="keyConcepts"
                :is-loading="!currentModel || isLoading.keyConcepts"
                header-left="Key Concepts"
                header-right="#/% of all verbatims"
                @concept-clicked="sidebarConceptClicked"
              />
              <template v-if="ignoredConcepts.length > 0">
                <hr />
                <concept-list
                  class="tab-content ignored-concepts"
                  :concepts="ignoredConcepts"
                  :is-loading="!currentModel || isLoading.ignoredConceptsStats"
                  header-left="Ignored Concepts"
                  header-right=""
                  @concept-clicked="sidebarConceptClicked"
                />
              </template>
            </el-tab-pane>
            <el-tab-pane label="Themes" name="themes">
              <template v-if="!isLoading.ready">
                <div class="loading-wrapper">
                  <bf-spinner />
                </div>
              </template>
              <template v-else>
                <div class="buttons">
                  <bf-button size="mini" color="blue" @click="addTheme"> CREATE </bf-button>
                  <bf-button size="mini" color="green" @click="saveAllThemes"> SAVE ALL </bf-button>
                  <bf-button size="mini" color="transparent" class="discard-button" @click="resetButtonClick">
                    <icon name="revert" :size="14" color="#95a6ac" />
                    DISCARD ALL CHANGES
                  </bf-button>
                </div>
                <div class="tree-wrapper">
                  <el-input
                    class="filter-input"
                    :model-value="filterString"
                    placeholder="Filter groups & themes"
                    @update:model-value="filterString = $event"
                  />
                  <template v-if="stagedNewQueries.value.length > 0">
                    <theme-tree
                      ref="newTree"
                      :headers="['In Progress', '% of all verbatims']"
                      :project-id="currentProject.id"
                      :analysis-id="currentAnalysis.id"
                      :fetch-column-data="fetchColumnData"
                      :is-percent="[true]"
                      :fetch-tree="fetchNewTree"
                      :focus-node="focusNode"
                      :allow-drag-drop="false"
                      :loading-keys="loadingKeys"
                      :show-expand-text="false"
                      :max-bar-value="100"
                      @name-click="treeClick($event)"
                      @node-toggle="() => {}"
                    />
                    <hr />
                  </template>
                  <div class="relative">
                    <bf-button
                      v-if="!tree?.isEmpty"
                      class="create-group"
                      color="transparent"
                      @click="createGroupModalVisible = true"
                    >
                      <icon color="#068ccc" name="plus" :size="11" />
                      Create Group
                    </bf-button>
                    <theme-tree
                      ref="tree"
                      :headers="['Themes', '% of all verbatims']"
                      :project-id="currentProject.id"
                      :analysis-id="currentAnalysis.id"
                      :fetch-column-data="fetchColumnData"
                      :is-percent="[true]"
                      :fetch-tree="fetchTree"
                      :focus-node="focusNode"
                      :allow-drag-drop="true"
                      :loading-keys="loadingKeys"
                      :filter-string="filterString.length > 1 ? filterString : ''"
                      :unsaved-map="hasChanges"
                      :show-expand-text="false"
                      :max-bar-value="100"
                      :force-expand="filterString.length > 1"
                      :is-auto-theme-analysis="isAutoThemeAnalysis"
                      @tree-loading="isLoading.themes = $event"
                      @name-click="treeClick($event)"
                      @node-toggle="() => {}"
                      @node-moved="nodeMoved"
                      @context-command="dropdownClick"
                    >
                      <template #empty>
                        <div class="empty-message">Nothing found.</div>
                      </template>
                      <template #loading>
                        <div class="tree-loading">
                          <bf-spinner />
                        </div>
                      </template>
                      <template #contextMenu="{ data, level }">
                        <el-dropdown-menu>
                          <el-dropdown-item
                            :command="{
                              action: 'rename',
                              type: data.type,
                              id: data.id,
                            }"
                          >
                            Rename
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="data.type === 'group' && level > 0"
                            :command="{
                              action: 'move_up',
                              type: data.type,
                              id: data.id,
                            }"
                          >
                            Move Level Up
                          </el-dropdown-item>
                          <el-dropdown-item
                            v-if="data.type === 'group' || !autoThemeIds.includes(data.id)"
                            :command="{
                              action: 'delete',
                              type: data.type,
                              id: data.id,
                            }"
                            class="red"
                          >
                            Delete
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </theme-tree>
                  </div>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="content">
        <theme-page
          :is-new-theme="isNewTheme"
          :selected-botanic-query="selectedExpandedBotanicQuery ?? null"
          :selected-query-scope="selectedQueryScope"
          :selected-query="selectedQuery"
          :selected-query-rows="selectedQueryRows"
          :query-stats="queryStats"
          :staged-query-is-valid="stagedQueryIsValid"
          :is-loading-stats="isLoading.stats"
          :ignored-concept-names="ignoredConceptNames"
          :staged-queries="stagedQueries"
          :saved-queries="savedQueries.value"
          :save-theme="saveOrUpdateTheme"
          :has-changes="!!(selectedQuery && hasChanges[selectedQuery.id])"
          :total-verbatims="queryStats.total_verbatim_count"
          :top-level-groups="topLevelGroups"
          :is-auto-theme-analysis="isAutoThemeAnalysis"
          @delete-theme="deleteQuery = $event"
          @rename-theme="renameQuery = $event"
          @change-query-scope="changeQueryScope"
          @select-theme="selectTheme"
          @set-query="setQuery"
          @add-theme="addTheme"
          @set-selected="selectedQuery = $event"
          @go-to-unmapped="goToUnmappedPage"
          @unignore-concept="unignoreConcept"
          @ignore-concept="ignoreConcept"
          @theme-renamed="themeRenamed"
        >
        </theme-page>
      </div>
    </div>

    <!-- Reset confirm modal -->
    <confirm-modal ref="resetConfirmModal" title="Reset saved Themes">
      This will discard all unsaved changes to saved themes.
      <br />
      Are you sure you want to continue?
    </confirm-modal>

    <clicked-outside :enabled="!!clickedConcept.name" @clicked-outside="clearClickedConcept">
      <floating-panel v-if="!!clickedConcept.name" :x="clickedConcept.x" :y="clickedConcept.y + 8" visible>
        <div class="interaction-menu">
          <button v-if="selectedQuery" @click="addConceptFromSidebar(clickedConcept.name, false)">
            Add "<b>{{ clickedConcept.name }}</b
            >" to {{ selectedQuery.name }}
          </button>
          <button @click="addConceptFromSidebar(clickedConcept.name, true)">
            Create new theme with "<b>{{ clickedConcept.name }}</b
            >"
          </button>
          <button v-if="hideMappedSidebar" @click="goToUnmappedPage(clickedConcept.name)">
            View "<b>{{ clickedConcept.name }}</b
            >" on unmapped page
          </button>
          <template v-if="ignoredConceptNames.includes(clickedConcept.name)">
            <button @click="unignoreConcept(clickedConcept.name)">
              Unignore "<b>{{ clickedConcept.name }}</b
              >"
            </button>
          </template>
          <template v-else>
            <button @click="ignoreConcept(clickedConcept.name)">
              Ignore "<b>{{ clickedConcept.name }}</b
              >"
            </button>
          </template>
        </div>
      </floating-panel>
    </clicked-outside>

    <theme-delete-modal
      v-if="deleteQuery"
      :visible="deleteQuery != null"
      :selected-query="deleteQuery"
      :saved-queries="savedQueries.value"
      :delete-theme="deleteThemeClick"
      @close="deleteQuery = null"
    />

    <theme-name-modal
      :visible="!!renameQuery"
      :is-new-theme="renameQuery?.[0].is_new"
      :submit-errors="modalErrors"
      :values="{
        name: renameQuery?.[0].name,
        description: renameQuery?.[0].description,
      }"
      :dashboard-list="currentAnalysis.dashboards || []"
      :can-add-to-dashboard="canAddToDashboard"
      :dashboard-ids="renameQuery?.[0].dashboard_ids || []"
      :theme-group-list="themeGroups"
      @close="renameQuery = null"
      @update-theme="renameFormSubmit"
    />

    <group-name-modal
      :visible="renameGroup !== null"
      :values="{ name: renameGroup?.name }"
      :submit-errors="modalErrors"
      @close="renameGroup = null"
      @submit="renameGroupSubmit"
    />

    <group-delete-modal
      :visible="deleteGroup !== null"
      :group-name="deleteGroup?.name ?? ''"
      @close="deleteGroup = null"
      @submit="deleteGroupSubmit"
    />

    <create-group-modal
      :visible="createGroupModalVisible"
      :submit-errors="modalErrors"
      :project-id="projectId"
      :analysis-id="analysisId"
      @close="createGroupModalVisible = false"
      @group-created="groupCreated"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, computed, watch, ref, reactive, inject, PropType } from 'vue'
import { isEqual, cloneDeep, uniqBy } from 'lodash'
import { onBeforeRouteLeave, useRoute } from 'vue-router'

import {
  fetchThemes,
  useFetchData,
  getQueryRows,
  isQueryValid,
  makeNewThemeName,
  getConceptsFromQuery,
  CoverageNode,
  findParentNode,
  findNode,
} from './ThemeBuilder.utils'
import { SavedQuery, QueryElement, QueryType, QueryLocation } from 'src/types/Query.types'
import { Analysis, Concept } from 'src/types/AnalysisTypes'
import { BfButton, BfSpinner } from 'components/Butterfly'
import QueryUtils, { hasUnstructured, expandQuery } from 'src/utils/query'
import Query, { UnmappedBodyType, expandQueryIfPossible, expandSavedQueryIfPossible, ThemeGroup } from 'src/api/query'
import ProjectAPI from 'src/api/project'
import ConceptList from './ConceptList.vue'
import { fetch_pivot_data } from 'src/store/modules/data/api'
import { useStore } from 'src/store'
import { CLEAR_REQUEST_ERRORS, SET_SAVED_QUERIES } from 'src/store/types'
import { Analytics } from 'src/analytics'
import Icon from 'components/Icon.vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import ClickedOutside from 'components/ClickedOutside.vue'
import { comma, percent } from 'src/utils/formatters'
import ConfirmModal from 'components/ConfirmModal.vue'
import ToggleSliderInput from 'components/forms/ToggleSliderInput.vue'
import ProgressBar from './ProgressBar.vue'
import { useRouter } from 'src/router'
import { PivotData } from 'src/types/widgets.types'
import { expandThemeGroup, Group, GroupOrTheme } from 'src/pages/dashboard/Dashboard.utils'
import ThemeTree, { createNode } from 'src/components/DataWidgets/ThemesWidget/ThemeTree.vue'
import ThemePage from './ThemePage.vue'
import { RunQueryResponse } from 'src/pages/trial/Workbench/Workbench.utils'
import { sleep } from 'src/utils/general'
import ThemeDeleteModal from './ThemeDeleteModal.vue'
import ThemeNameModal from './ThemeNameModal.vue'
import GroupNameModal from './GroupNameModal.vue'
import GroupDeleteModal from './GroupDeleteModal.vue'
import CreateGroupModal from './CreateGroupModal.vue'
import { NodeData } from 'src/components/DataWidgets/ThemesWidget/LazyTreeNode.vue'
import Utils from 'src/utils/general'

// Interfaces and Types
interface CoverageStats {
  all_frames: number
  frames_covered: number
  query_stats: CoverageNode[]
}

interface FetchedDataPayload {
  status: 'done' | 'fetching'
  data:
    | {
        name: string
        frequency: number
        nonEmptyCoverage: number
      }[]
    | undefined
  error: string | undefined
}

interface PayloadNode {
  id: string | number
  name: string
  type: 'theme' | 'group'
  children?: PayloadNode[]
  value?: SavedQuery['query_value']
  omit_from_overall: boolean
}

interface Coverage {
  all_frames: number
  frames_covered: number
}

const ERROR = {
  FETCH_THEMES: 'Failed to fetch themes.',
} as const

const ThemeBuilder = defineComponent({
  components: {
    BfButton,
    BfSpinner,
    ConceptList,
    Icon,
    FloatingPanel,
    ClickedOutside,
    ConfirmModal,
    ToggleSliderInput,
    ProgressBar,
    ThemeTree,
    ThemePage,
    ThemeDeleteModal,
    ThemeNameModal,
    GroupNameModal,
    GroupDeleteModal,
    CreateGroupModal,
  },
  provide: {
    isOnDashboard: false,
  },
  props: {
    analysisId: { type: Number, required: true },
    projectId: { type: Number, required: true },
    currentModel: { type: Object, required: true },
    currentAnalysis: { type: Object as PropType<Analysis>, required: true },
    currentSite: { type: Object, required: true },
    currentProject: { type: Object, required: true },
    currentUser: { type: Object, required: true },
    featureFlags: { type: Object, required: true },
    hasNPS: { type: Boolean, required: false, default: false },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasDate: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const analytics = inject<Analytics>('analytics')
    const { fetch } = useFetchData()

    const isLoading = reactive({
      state: true,
      themes: false,
      coverage: false,
      keyConcepts: false,
      stats: false,
      ignoredConceptsStats: false,
      ready: false,
      globalStats: true,
    })

    // Theme Management
    const themeGroups = computed(() => (store.getters['themeGroups'] ?? []) as Group[])
    const savedQueries = reactive<{ value: SavedQuery[] }>({ value: [] })
    const stagedSavedQueries = reactive<{ value: SavedQuery[] }>({ value: [] })
    const stagedNewQueries = reactive<{ value: SavedQuery[] }>({ value: [] })
    const stagedQueries = computed(() =>
      stagedSavedQueries.value.concat(stagedNewQueries.value).sort((a, b) => (a.name > b.name ? 1 : -1)),
    )
    const selectedQueryID = ref<number | null>(null)
    const selectedQuery = computed<SavedQuery | null>({
      get() {
        return stagedQueries.value.find((q) => q.id === selectedQueryID.value) ?? null
      },
      set(value: SavedQuery | null) {
        if (!value) return
        const replaceId = (arr: SavedQuery[]) => arr.map((q) => (q.id === value.id ? value : q))
        if (value.is_new) {
          stagedNewQueries.value = replaceId(stagedNewQueries.value)
        } else {
          stagedSavedQueries.value = replaceId(stagedSavedQueries.value)
        }
      },
    })
    const isNewTheme = computed(() => !!selectedQuery.value?.is_new)

    const isAutoThemeAnalysis = computed(() => {
      return props.currentAnalysis.automatic_theme_generation
    })

    const changeQueryScope = (newScope: 'frame' | 'sentence') => {
      stagedQueries.value.map((q) => {
        if (q.id === selectedQueryID.value) q.query_value.level = newScope
      })
      updateSelectedStats()
    }

    const loadThemes = (overwriteStaged = true) => {
      isLoading.themes = true
      Object.assign(savedQueries, [])
      return fetchThemes(props.projectId, props.analysisId)
        .then((res) => {
          savedQueries.value = cloneDeep(res)
          store.commit(SET_SAVED_QUERIES, cloneDeep(res))
          if (overwriteStaged) {
            stagedSavedQueries.value = cloneDeep(res)
          }
          return updateGlobalStats()
        })
        .catch(() => (error.value = ERROR.FETCH_THEMES))
        .finally(() => (isLoading.themes = false))
    }

    const selectTheme = (id: number | null) => {
      selectedQueryID.value = id
    }

    const addTheme = (copy: Partial<SavedQuery> = {}) => {
      const newTheme: SavedQuery = {
        name: makeNewThemeName(stagedQueries.value),
        description: '',
        query_value: {
          level: props.currentProject.query_scope_default ?? 'frame',
        } as SavedQuery['query_value'],
        dashboard_ids: [],
        ...copy,
        analysis: props.analysisId,
        project: props.projectId,
        created: '',
        modified: '',
        is_new: true,
        id: -Date.now(),
        exclude_mapped: false,
        theme_group: null,
      }
      stagedNewQueries.value.push(newTheme)
      selectTheme(newTheme.id)
      newTree.value?.resetTree()
    }

    const deleteTheme = async (theme: SavedQuery, conflicts: SavedQuery[] = []) => {
      const removeOrReplaceState = (theme: SavedQuery, replace?: SavedQuery) => {
        if (theme.is_new) {
          const index = stagedNewQueries.value.findIndex((q) => q.id === theme.id)
          replace ? stagedNewQueries.value.splice(index, 1, replace) : stagedNewQueries.value.splice(index, 1)
        } else {
          let index = stagedSavedQueries.value.findIndex((q) => q.id === theme.id)
          replace ? stagedSavedQueries.value.splice(index, 1, replace) : stagedSavedQueries.value.splice(index, 1)
          index = savedQueries.value.findIndex((q) => q.id === theme.id)
          replace ? savedQueries.value.splice(index, 1, replace) : savedQueries.value.splice(index, 1)
        }
        if (selectedQueryID.value === theme.id) {
          selectedQueryID.value = null
        }
      }

      const requests = conflicts.map(async (conflict) => {
        const level = conflict.query_value.level ? conflict.query_value.level : 'frame'
        const rows = QueryUtils.botanicToQueryRows(conflict.query_value)
        rows.forEach((row, i) => {
          if (row.type === 'query') {
            row.values = row.values?.filter((v) => v !== theme.id.toString())
            if (!row.values?.length) {
              rows.splice(i, 1)
            }
          }
        })
        return rows.length === 0 ?
            Query.deleteSavedQuery(conflict.project, conflict.analysis, conflict.id).then(() => {
              removeOrReplaceState(conflict)
            })
          : Query.updateSavedQueryV2(conflict.project, conflict.analysis, {
              query_value: QueryUtils.queryRowsToBotanic(rows, level),
              id: conflict.id,
            }).then(() => {
              removeOrReplaceState(conflict, {
                ...conflict,
                query_value: QueryUtils.queryRowsToBotanic(rows, level),
              })
            })
      })

      await Promise.all(requests)

      if (isNewTheme.value) {
        removeOrReplaceState(theme)
        newTree.value?.removeNode(`theme_${theme.id}`)
      } else {
        await Query.deleteSavedQuery(props.projectId, props.analysisId, theme.id).then(async () => {
          removeOrReplaceState(theme)
          const parent = tree.value?.getParentNode(`theme_${theme.id}`)
          tree.value?.removeNode(`theme_${theme.id}`)
          if (parent) updateNodeCoverage([parent])
          updateGlobalStats()
          analytics?.track.themeBuilder.deleteTheme(theme.id)
        })
      }
    }

    const saveOrUpdateTheme = async (query: SavedQuery, partial?: Partial<SavedQuery>) => {
      const rows = getQueryRows(query.query_value)
      if (!isQueryValid(rows)) return
      const saveMethod = query.is_new ? Query.createSavedQueryV2 : Query.updateSavedQueryV2
      const payload = { ...query, ...(partial ?? {}) }
      const res = await saveMethod(props.projectId, props.analysisId, payload)
      if (query.is_new) {
        analytics?.track.themeBuilder.createTheme(query.name, query.id)
      } else {
        analytics?.track.themeBuilder.saveTheme(query.name, query.id, JSON.stringify(query.query_value))
      }
      await loadThemes(false)
      newTree.value?.removeNode(`theme_${query.id}`)
      stagedNewQueries.value = stagedNewQueries.value.filter((q) => q.id !== query.id)
      const savedQuery = res.body ?? res
      const index = stagedSavedQueries.value.findIndex((q) => q.id === savedQuery.id)
      if (index !== -1) {
        stagedSavedQueries.value[index] = partial ? { ...stagedSavedQueries.value[index], ...partial } : savedQuery
      } else {
        stagedSavedQueries.value.push(savedQuery)
        const node = createNode({ id: savedQuery.id, name: savedQuery.name, type: 'theme' })
        tree.value?.insertNode(savedQuery.theme_group ?? -1, node)
        updateNodeCoverage([node])
      }
      setTimeout(() => {
        selectedQueryID.value = query.id === selectedQueryID.value ? savedQuery.id : selectedQueryID.value
      })
    }

    const saveAllThemes = async () => {
      const requests = stagedQueries.value.map((q) =>
        hasChanges.value[q.id] ? saveOrUpdateTheme(q, undefined) : Promise.resolve(),
      )
      await Promise.all(requests)
      await resetState()
    }

    const topLevelGroups = computed(() => {
      const treeData = tree.value?.treeData ?? []

      return treeData
        .map((node) => {
          if (node.type === 'group') {
            return findNode(treeData, { id: node.id, type: 'group' }) ?? null
          }
          return null
        })
        .filter(Boolean) as Group[]
    })

    // Group Management
    const renameGroup = ref<Group | null>(null)
    const deleteGroup = ref<Group | null>(null)
    const createGroupModalVisible = ref(false)

    const renameGroupSubmit = async ({ name }: { name: string }) => {
      modalErrors.value = []
      if (renameGroup.value !== null) {
        try {
          const id = Number(renameGroup.value.id)
          await ThemeGroup.update(props.projectId, props.analysisId, id, { group_name: name })
          tree.value?.updateNodeData(`group_${id}`, { name: name })
          analytics?.track.themeBuilder.renameThemeGroup(renameGroup.value.name, name, id)
          renameGroup.value = null
        } catch (e) {
          console.warn(e)
        }
      }
    }

    const deleteGroupSubmit = async () => {
      if (deleteGroup.value !== null) {
        const id = Number(deleteGroup.value.id)
        const key = `group_${id}`
        await ThemeGroup.delete(props.projectId, props.analysisId, id)
        const treeData = tree.value!.treeData
        const treeNode = findNode(treeData, { key })
        const treeParent = findParentNode(treeData, { key }) ?? findNode(treeData, { id: -1, type: 'group' })
        if (treeNode?.children && treeParent?.children) {
          const children = [...treeParent.children, ...treeNode.children]
          treeParent.children = children
          updateNodeCoverage([treeParent, ...treeNode.children])
        }
        tree.value?.removeNode(key)
        analytics?.track.themeBuilder.deleteThemeGroup(deleteGroup.value.name, id)
        deleteGroup.value = null
      }
    }

    const groupCreated = async (group: Group, parentId: number | null) => {
      const treeData = tree.value!.treeData
      const parent = parentId ? findNode(treeData, { id: parentId, type: 'group' })?.children : treeData
      if (!parent) return
      const node = createNode(group)
      const toUpdate = [node]
      if (node.children) {
        node.children = node.children
          .map((child) => {
            const childNode = findNode(treeData, { id: child.id, type: child.type })
            const childParent = findParentNode(treeData, { id: child.id, type: child.type })
            if (childParent) toUpdate.push(childParent)
            if (childNode) {
              tree.value?.removeNode(child.key)
              return childNode
            }
          })
          .filter(Boolean) as NodeData[]
      }
      tree.value?.insertNode(parentId, node)
      tree.value?.sortTree()
      updateNodeCoverage(toUpdate)
    }

    // Concept Handling
    const allConcepts = computed<Concept[]>(() => props.currentModel?.topics_list)
    const clickedConcept = ref<{ name: string | null; x: number; y: number }>({
      name: null,
      x: 0,
      y: 0,
    })
    const ignoredConceptNames = ref<string[]>(props.currentAnalysis.ignored_concepts ?? [])
    const ignoredConcepts = computed(() =>
      allConcepts.value.filter((c) => {
        if (hideMappedSidebar.value && mappedConcepts.value.includes(c.name)) return false
        return ignoredConceptNames.value.includes(c.name)
      }),
    )
    const mappedConcepts = computed(() =>
      stagedQueries.value.reduce((acc, q) => acc.concat(getConceptsFromQuery(q)), [] as string[]),
    )
    const keyConcepts = computed(() =>
      allConcepts.value.filter((c) => {
        if (hideMappedSidebar.value && mappedConcepts.value.includes(c.name)) return false
        return !ignoredConceptNames.value.includes(c.name)
      }),
    )
    const hideMappedSidebar = ref(false)

    watch(ignoredConceptNames, (newVal, oldVal) => {
      if (isEqual(newVal, oldVal)) return
      updateGlobalStats()
      ProjectAPI.updateAnalysisDisplayAttributes(store, { ignored_concepts: newVal })
    })

    const addConceptFromSidebar = (concept: string, newTheme: boolean) => {
      if (newTheme) {
        addTheme()
        setQuery([{ type: 'text', values: [concept], operator: 'includes' }])
      } else {
        themePage.value?.addConceptToQuery(concept)
      }
      clearClickedConcept()
      themePage.value?.scrollToTop()
    }

    const sidebarConceptClicked = (concept: string, el: HTMLElement) => {
      const { x, y } = el.getBoundingClientRect()
      clickedConcept.value = { name: concept, x: x + 10, y: y }
    }

    const clearClickedConcept = () => {
      clickedConcept.value = { name: null, x: 0, y: 0 }
    }

    const ignoreConcept = (concept: string) => {
      ignoredConceptNames.value = ignoredConceptNames.value.concat([concept])
      clearClickedConcept()
      analytics?.track.themeBuilder.ignoreConcept(concept)
    }

    const unignoreConcept = (concept: string) => {
      ignoredConceptNames.value = ignoredConceptNames.value.filter((c) => c !== concept)
      clearClickedConcept()
    }

    // Coverage and Stats
    const totalCoverage = ref<Coverage>({ all_frames: 0, frames_covered: 0 })
    const progressCoverage = computed(() => {
      const data = totalCoverage.value
      const all_frames = Math.max(data.all_frames, 1)
      return Math.round((data.frames_covered / all_frames) * 100) || 0
    })
    const queryStats = reactive({
      record_count: 0,
      verbatim_count: 0,
      total_record_count: 0,
      total_verbatim_count: 0,
      ignored_verbatim_count: 0,
    })

    const fetchCoverage = (queries: PayloadNode[]): Promise<[Coverage, CoverageNode[]]> => {
      const fetchParams = [
        props.projectId,
        queries,
        props.currentAnalysis.topic_framework_id,
        props.currentProject.chrysalis_ref,
      ]
      const cacheKey = { name: 'coverageV2', fetchParams }
      return fetch<CoverageStats>(cacheKey, Query.getThemeStatsV2, fetchParams).then((result) => [
        result,
        result.query_stats,
      ])
    }

    const updateNodeCoverage = async (target: { id: GroupOrTheme['id']; type: GroupOrTheme['type'] }[]) => {
      const treeData = tree.value!.treeData.concat(newTree.value?.treeData ?? [])
      const nodes = target
        .slice()
        .map((node) => node && findNode(treeData, { id: Number(node.id), type: node.type }))
        .filter(Boolean) as NodeData[]
      nodes.slice().forEach((node) => {
        let parent = findParentNode(treeData, { id: Number(node.id), type: node.type })
        while (parent) {
          nodes.push(parent)
          parent = findParentNode(treeData, { id: parent.id, type: parent.type })
        }
      })
      const uniqueNodes = uniqBy(nodes, 'key')
      return fetchColumnData(uniqueNodes, true)
    }

    const updateSelectedCoverage = async () => {
      const id = selectedQueryID.value
      if (id == null || !hasChanges.value[id] || !selectedQuery.value || !stagedQueryIsValid.value) return
      await updateNodeCoverage([{ id, type: 'theme' }])
    }

    const loadIgnoredConceptsStats = () => {
      if (ignoredConceptNames.value.length === 0) return
      isLoading.ignoredConceptsStats = true
      const queries = ignoredConceptNames.value.map((c: string) => ({
        name: c,
        query_value: {
          type: 'match_all',
          includes: [
            { type: 'text', value: c },
            { type: 'segment', field: 'Token Count', operator: '<=', value: '2' },
          ],
        },
      }))
      const fetchParams = [
        props.projectId,
        queries,
        props.currentAnalysis.topic_framework_id,
        props.currentProject.chrysalis_ref,
      ]
      const cacheKey = { name: 'ignoredConceptsStats', fetchParams }
      return fetch<CoverageStats>(cacheKey, Query.getThemeStats, fetchParams)
        .then((result) => {
          fetchedData['ignored-concepts-stats'] = {
            status: 'done',
            error: undefined,
            data: result?.query_stats.map((c) => ({
              name: c.name,
              frequency: c.num_hits,
              nonEmptyCoverage: c.coverage,
            })),
          }
        })
        .catch((error) => {
          fetchedData['ignored-concepts-stats'] = { status: 'done', error, data: undefined }
        })
        .finally(() => (isLoading.ignoredConceptsStats = false))
    }

    const fetchVerbatimCount = () => {
      const query = {
        includes: [selectedExpandedBotanicQuery.value, { type: 'nonempty_data' }],
        excludes: [],
        type: 'match_all',
      }
      const countFetchParams = [props.projectId, props.analysisId, query, savedQueries.value, { start: 0, limit: 0 }]
      const countCacheKey = { countFetchParams, name: 'verbatim_count' }
      return Promise.all([
        fetch<RunQueryResponse>(countCacheKey, Query.runQuery, countFetchParams).then((result) => {
          queryStats.verbatim_count = result.count
        }),
      ])
    }

    const fetchIgnoredVerbatimCount = () => {
      const validStagedQueries = stagedQueries.value.reduce((arr, q) => {
        const rows = getQueryRows(q.query_value)
        if (!isQueryValid(rows)) return arr
        const query_value = QueryUtils.queryRowsToBotanic(rows, q.query_value.level ?? 'frame')
        return arr.concat({ ...q, query_value })
      }, [] as SavedQuery[])
      const base_query: QueryType = {
        level: 'frame',
        type: 'match_all',
        includes: [
          { type: 'match_any', includes: ignoredConceptNames.value.map((c) => ({ type: 'text', value: c })) },
          { type: 'match_any', includes: [{ type: 'segment', field: 'Token Count', operator: '<=', value: '2' }] },
        ],
      }
      const exclude_queries_list = validStagedQueries.map((q) => ({
        value: expandSavedQueryIfPossible(q.name, q.query_value, savedQueries.value),
        name: q.name,
      }))
      const unmappedBody: UnmappedBodyType = { base_query, exclude_queries_list }
      const countFetchParams = [
        props.projectId,
        unmappedBody,
        props.currentAnalysis.topic_framework_id,
        props.currentProject.chrysalis_ref,
        { start: 0, limit: 0, documents: false, networkModel: false },
      ]
      const totalFetchParams = [
        props.projectId,
        props.analysisId,
        { type: 'match_all', includes: [{ type: 'all_data' }, { type: 'nonempty_data' }] },
        savedQueries.value,
        { start: 0, limit: 0 },
      ]
      const countCacheKey = { countFetchParams, name: 'ignored_verbatim_count' }
      const totalCacheKey = { countFetchParams, name: 'total_verbatim_count' }
      const fetchCoverage =
        ignoredConceptNames.value.length === 0 ?
          Promise.resolve({ count: 0, total_hits: 0 })
        : fetch<RunQueryResponse>(countCacheKey, Query.runUnmapped, countFetchParams)
      return Promise.all([
        fetchCoverage.then((result) => {
          queryStats.ignored_verbatim_count = result.total_hits
        }),
        fetch<RunQueryResponse>(totalCacheKey, Query.runQuery, totalFetchParams).then((result) => {
          queryStats.total_verbatim_count = result.count
        }),
      ])
    }

    const fetchRecordCount = () => {
      const requirements = {
        blocks: [{ aggfuncs: [{ new_column: 'frequency_cov', src_column: 'document_id', aggfunc: 'count' }] }],
        queries: [{ name: 'filtered_query', value: selectedExpandedBotanicQuery.value }],
      }
      const fetchParams = [
        {
          projectId: props.projectId,
          chrysalisRef: props.currentProject.chrysalis_ref,
          topicId: props.currentAnalysis.topic_framework_id,
        },
        requirements,
      ]
      const cacheKey = { fetchParams, name: 'record_count' }
      return fetch<PivotData>(cacheKey, fetch_pivot_data, fetchParams).then((result) => {
        const count = result.payload.find((d) => d.group__ === 'filtered_query')?.frequency_cov
        const overall = result.payload.find((d) => d.group__ === 'overall__')?.frequency_cov
        queryStats.record_count = +(count ?? 0)
        queryStats.total_record_count = +(overall ?? 0)
      })
    }

    const fetchOverallCoverage = async () => {
      const treeData = tree.value?.treeData
      if (!treeData) return
      const payload = nodesToPayload(treeData)
      const validNewQueries = stagedNewQueries.value.filter(({ query_value }) =>
        isQueryValid(getQueryRows(query_value)),
      )
      validNewQueries.forEach((q) => {
        const expanded = expandQuery(q.name, q.query_value, savedQueries.value)
        payload.push({
          id: `new_theme_${q.id}`,
          name: q.name,
          type: 'theme',
          value: expanded,
          omit_from_overall: !hasUnstructured(q.query_value),
        })
      })
      const [stats] = await fetchCoverage(payload)
      totalCoverage.value.all_frames = stats.all_frames
      totalCoverage.value.frames_covered = stats.frames_covered
    }

    const updateGlobalStats = async () => {
      if (!tree.value) return
      isLoading.globalStats = true
      await Promise.all([fetchOverallCoverage(), loadIgnoredConceptsStats(), fetchIgnoredVerbatimCount()])
      isLoading.globalStats = false
    }

    watch(
      () => isLoading.themes,
      (newVal, oldVal) => {
        if (newVal === false && oldVal === true) updateGlobalStats()
      },
    )

    const updateSelectedStats = async () => {
      isLoading.stats = true
      const minTime = sleep(500)
      await Promise.all([
        updateGlobalStats(),
        updateSelectedCoverage(),
        stagedQueryIsValid.value && fetchVerbatimCount(),
        stagedQueryIsValid.value && fetchRecordCount(),
      ])
      await minTime
      isLoading.stats = false
    }

    // UI State and Interactions
    const tree = ref<InstanceType<typeof ThemeTree>>()
    const newTree = ref<InstanceType<typeof ThemeTree>>()
    const themePage = ref<InstanceType<typeof ThemePage>>()
    const resetConfirmModal = ref<InstanceType<typeof ConfirmModal>>()
    const error = ref<null | (typeof ERROR)[keyof typeof ERROR]>(null)
    const fetchedData = reactive({ 'ignored-concepts-stats': {} as FetchedDataPayload })
    const activeTab = ref<'concepts' | 'themes'>('themes')
    const filterString = ref('')
    const modalErrors = ref<string[]>([])
    const loadingKeys = ref<string[]>([])
    const deleteQuery = ref<SavedQuery | null>(null)
    const renameQuery = ref<[SavedQuery, boolean] | null>(null)

    const resetButtonClick = async () => {
      const confirm = await resetConfirmModal.value?.confirm()
      if (confirm) {
        analytics?.track.themeBuilder.discardAllChanges()
        resetState(true)
      }
    }

    const resetState = async (resetNew = true) => {
      queryStats.record_count = 0
      queryStats.total_record_count = 0
      queryStats.verbatim_count = 0
      queryStats.total_verbatim_count = 0
      selectedQueryID.value = null
      stagedSavedQueries.value = []
      totalCoverage.value = { all_frames: 0, frames_covered: 0 }
      if (resetNew) stagedNewQueries.value = []
      await loadThemes(true)
      const nodes = tree.value?.getVisibleNodes()
      nodes && updateNodeCoverage(nodes)
    }

    const fetchColumnData = async (nodes: NodeData[], replace = false): Promise<number[][]> => {
      const payload = nodesToPayload(nodes)
      if (replace) nodes.forEach((query) => loadingKeys.value.push(query.key))
      const [, items] = await fetchCoverage(payload)
      return nodes.map((node) => {
        const item = items.find((i) => i.id === node.key)
        const coverage = item?.coverage ?? 0
        const data = [coverage * 100]
        if (replace) {
          tree.value?.updateColumnData(node.key, data)
          newTree.value?.updateColumnData(node.key, data)
          loadingKeys.value = loadingKeys.value.filter((key) => key !== node.key)
        }
        return data
      })
    }

    const nodesToPayload = (items: NodeData[]): PayloadNode[] =>
      items.reduce((arr, item) => {
        let newArr = arr.slice()
        if (item.type === 'group' && item.children) {
          const group = expandThemeGroup(item as Group, stagedSavedQueries.value)
          const valid = item.children.length > 0
          valid &&
            newArr.push({
              id: `group_${group.id}`,
              name: group.name,
              type: 'theme',
              value: group.query_value,
              omit_from_overall: item.children.length === 0,
            } as PayloadNode)
        } else if (item.type === 'theme') {
          const query = stagedQueries.value.find((q) => q.id === Number(item.id))
          if (query) {
            const expanded = expandQuery(query.name, query.query_value, savedQueries.value)
            const valid = isQueryValid(getQueryRows(expanded))
            valid &&
              newArr.push({
                id: `theme_${query.id}`,
                name: query.name,
                type: 'theme',
                value: expanded,
              } as PayloadNode)
          } else {
            console.error(new Error(`Saved Query not found for theme ${item.id}`))
          }
        }
        return newArr
      }, [] as PayloadNode[])

    const makeThemesTree = (tree: GroupOrTheme[]) => {
      const groups = tree.filter((node) => node.type === 'group')
      const themes = tree.filter((node) => node.type === 'theme')
      const name = isAutoThemeAnalysis.value ? 'New themes' : 'Ungrouped themes'
      groups.push({ id: -1, name, type: 'group', children: themes })
      return groups
    }

    const fetchTree = async (): Promise<GroupOrTheme[]> => {
      const { group_tree } = await ThemeGroup.list(props.projectId, props.analysisId)
      return makeThemesTree(group_tree)
    }

    const fetchNewTree = async (): Promise<GroupOrTheme[]> =>
      stagedNewQueries.value.map((q) => ({
        id: q.id,
        name: q.name,
        type: 'theme',
        children: [],
      }))

    const themeRenamed = (id: number, name: string) => {
      tree.value?.updateNodeData(`theme_${id}`, { name: name })
    }

    const focusNode = computed<NodeData | null>(() =>
      selectedQuery.value ?
        {
          type: 'theme',
          id: selectedQuery.value.id,
          name: selectedQuery.value.name,
          key: `theme_${selectedQuery.value.id}`,
        }
      : null,
    )

    const nodeMoved = async (child: NodeData, oldParent: NodeData, newParent: NodeData) => {
      updateNodeCoverage([child, oldParent])
      tree.value?.sortTree()
      analytics?.track.themeBuilder.moveTheme(
        { name: child.name, id: child.id.toString() },
        newParent.type === 'group' ?
          { name: newParent.name, id: newParent.id.toString() }
        : { id: newParent.id.toString() },
      )
    }

    const dropdownClick = async (command: {
      action: 'rename' | 'delete' | 'move_up'
      type: 'group' | 'theme'
      id: string
    }): Promise<void> => {
      const id = Number(command.id)
      switch (command.action) {
        case 'delete':
          if (command.type === 'theme') {
            const theme = stagedQueries.value.find((q) => q.id === id)
            if (theme) deleteQuery.value = theme
          } else if (command.type === 'group') {
            const group = themeGroups.value.find((q) => q.id === id)
            if (group) deleteGroup.value = group
          }
          break
        case 'rename':
          if (command.type === 'theme') {
            const theme = stagedQueries.value.find((q) => q.id === id)
            if (theme) renameQuery.value = [theme, false]
          } else if (command.type === 'group') {
            const group = themeGroups.value.find((q) => q.id === id)
            if (group) renameGroup.value = group
          }
          break
        case 'move_up':
          if (command.type !== 'group' || !tree.value) break
          try {
            const currentNode = tree.value.getNode(`group_${id}`)
            if (!currentNode) break
            const currentParent = tree.value.getParentNode(`group_${id}`)
            if (!currentParent) break
            const targetParent = tree.value.getParentNode(`group_${currentParent.id}`)
            const level = targetParent?.children ?? tree.value.treeData
            tree.value.removeNode(`group_${id}`)
            level.push(currentNode)
            tree.value.sortTree()
            updateNodeCoverage([currentParent, targetParent].filter((n): n is NodeData => Boolean(n)))
            const parentId = targetParent ? Number(targetParent.id) : null
            await ThemeGroup.updateGroupParent(props.projectId, props.analysisId, id, parentId)
          } catch (error) {
            console.warn('Error moving group up:', error)
          }
          break
      }
    }

    const deleteThemeClick = async (theme: SavedQuery, conflicts: SavedQuery[] = []) => {
      await deleteTheme(theme, conflicts)
      deleteQuery.value = null
    }

    const renameFormSubmit = async (query: SavedQuery, newTheme = false) => {
      if (!renameQuery.value) return
      modalErrors.value = []
      const [theme, duplicate] = renameQuery.value
      if (duplicate) {
        analytics?.track.themeBuilder.saveAsFromTheme(theme.id, query.name)
        addTheme({ ...theme, ...query })
        renameQuery.value = null
        return
      }
      let closeModal = true
      await saveOrUpdateTheme(theme, query).catch((res) => {
        store.dispatch(CLEAR_REQUEST_ERRORS)
        modalErrors.value = res.body?.non_field_errors ?? []
        closeModal = false
      })
      if (closeModal) {
        if (!newTheme && theme) themeRenamed(theme.id, query.name)
        renameQuery.value = null
        analytics?.track.themeBuilder.renameTheme(query.id, query.name)
      }
    }

    const treeClick = (node: NodeData) => {
      if (node.type === 'theme') {
        selectTheme(node.id)
      } else {
        node.expanded = !node.expanded
        setTimeout(tree.value!.resize)
      }
    }

    // Query Management
    const selectedQueryScope = computed(() => selectedQuery.value?.query_value.level ?? 'frame')
    const selectedQueryRows = ref<QueryElement[]>([])
    const selectedBotanicQuery = computed(() =>
      QueryUtils.queryRowsToBotanic(selectedQueryRows.value, selectedQueryScope.value),
    )
    const selectedExpandedBotanicQuery = computed(() =>
      expandQueryIfPossible(selectedBotanicQuery.value, savedQueries.value),
    )
    const stagedQueryIsValid = computed(() => isQueryValid(selectedQueryRows.value))
    const hasChanges = computed(() => {
      const hasChanged = (q1: SavedQuery, q2: SavedQuery) =>
        !isEqual(q1.query_value, q2.query_value) ||
        !isEqual(q1.name, q2.name) ||
        !isEqual(q1.description, q2.description) ||
        !isEqual(q1.dashboard_ids, q2.dashboard_ids)
      return stagedQueries.value.reduce(
        (map, query) => {
          const savedQuery = savedQueries.value.find((q) => q.id === query.id)
          map[query.id] = savedQuery != null ? hasChanged(query, savedQuery) : true
          return map
        },
        {} as Record<number, boolean>,
      )
    })
    const isUnsaved = computed(() => Object.values(hasChanges.value).some((v) => v))
    const autoThemeIds = computed(() =>
      savedQueries.value
        .filter((theme) => QueryUtils.botanicToQueryRows(theme.query_value).some((row) => row.field === 'aitopic'))
        .map((q) => q.id),
    )
    const canAddToDashboard = computed(() =>
      hasUnstructured(expandQuery('query', selectedBotanicQuery.value, savedQueries.value)),
    )

    watch(selectedQueryID, async (newVal) => {
      if (newVal == null || !selectedQuery.value?.query_value) {
        selectedQueryRows.value = []
        return
      }
      tree.value?.setExpandedNode({ id: newVal, type: 'theme' })
      const rows = getQueryRows(selectedQuery.value.query_value)
      selectedQueryRows.value = rows
      await updateSelectedStats()
    })

    const deleteRow = (index: number) => {
      const rows = selectedQueryRows.value.filter((_, i) => i !== index)
      setQuery(rows)
    }

    const setQuery = (rows: QueryElement[]) => {
      if (!selectedQuery.value) return
      selectedQueryRows.value = rows
      const botanic = QueryUtils.queryRowsToBotanic(rows, selectedQueryScope.value)
      selectedQuery.value = { ...selectedQuery.value, query_value: botanic }
      updateSelectedStats()
    }

    const setExcludeMapped = (value: boolean) => {
      selectedQuery.value = { ...selectedQuery.value, exclude_mapped: value } as SavedQuery
    }

    // Routing and Navigation
    onBeforeRouteLeave((to, from, next) => {
      if (isUnsaved.value) {
        const confirm = window.confirm('You have unsaved changes. Are you sure you want to leave?')
        confirm ? next() : next(false)
      } else {
        next()
      }
    })

    const goToUnmappedPage = (concept: string) => {
      analytics?.track.themeBuilder.viewOnUnmapped(concept)
      router.push({
        name: 'unmapped',
        params: {
          projectId: props.projectId.toString(),
          analysisId: props.analysisId.toString(),
          navigatedConcept: concept,
        },
      })
    }

    onMounted(async () => {
      await resetState(true)
      isLoading.ready = true

      // Create a new theme with route filters, if present
      setTimeout(() => {
        if (route.query.filters) {
          const filters = route.query.filters
          try {
            if (typeof filters === 'string') {
              const parsedFilters = Utils.parseRouteQuery(filters)
              addTheme()
              setQuery(parsedFilters)
            }
            router.replace({ query: {} })
          } catch (e) {
            console.error('Failed to parse filters from route:', e)
          }
        }
      })
    })

    return {
      error,
      savedQueries,
      selectTheme,
      isLoading,
      activeTab,
      deleteRow,
      setQuery,
      addTheme,
      changeQueryScope,
      resetState,
      fetchedData,
      isNewTheme,
      saveOrUpdateTheme,
      deleteTheme,
      stagedQueries,
      hasChanges,
      selectedQuery,
      selectedQueryID,
      selectedQueryRows,
      selectedBotanicQuery,
      selectedExpandedBotanicQuery,
      progressCoverage,
      stagedQueryIsValid,
      allConcepts,
      queryStats,
      percent,
      sidebarConceptClicked,
      clickedConcept,
      clearClickedConcept,
      addConceptFromSidebar,
      ignoreConcept,
      unignoreConcept,
      ignoredConcepts,
      ignoredConceptNames,
      selectedQueryScope,
      comma,
      resetConfirmModal,
      resetButtonClick,
      setExcludeMapped,
      hideMappedSidebar,
      keyConcepts,
      goToUnmappedPage,
      saveAllThemes,
      location: QueryLocation.ThemeBuilder,
      tree,
      fetchColumnData,
      fetchTree,
      themePage,
      stagedNewQueries,
      fetchNewTree,
      newTree,
      themeRenamed,
      focusNode,
      nodeMoved,
      loadingKeys,
      dropdownClick,
      autoThemeIds,
      deleteQuery,
      deleteThemeClick,
      renameQuery,
      themeGroups,
      renameFormSubmit,
      renameGroup,
      deleteGroup,
      renameGroupSubmit,
      deleteGroupSubmit,
      createGroupModalVisible,
      modalErrors,
      groupCreated,
      filterString,
      canAddToDashboard,
      treeClick,
      topLevelGroups,
      isAutoThemeAnalysis,
    }
  },
})

export default ThemeBuilder
</script>
<style lang="scss" scoped>
@import 'assets/kapiche.sass';

.theme-builder {
  display: flex;
  flex-direction: row;
  height: 100%;
  user-select: none;
  width: 100%;
  max-width: 100%;

  > .sidebar {
    $width: 400px;
    position: relative;
    min-width: $width;
    width: $width;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    margin-right: 20px;

    ::v-deep {
      .el-tabs__content {
        overflow-y: scroll;
        flex: 1;
      }
      .el-tabs {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    > .content {
      flex: 1;
      overflow-y: hidden;
    }
    .tab-title {
      padding: 0 20px;
      width: 100%;
      &:hover {
        color: $blue;
      }
    }
    .tab-content {
      padding-left: 26px;
      padding-right: 26px;
      flex: 1;
    }
    .active {
      color: $blue;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 0 26px;
      white-space: nowrap;
      margin: 0;

      .discard-button {
        color: $text-grey;
        .icon-wrapper {
          margin-right: 3px;
        }
      }
    }
  }
}
.filter-input {
  margin: 10px 0 20px;
}
.buttons {
  margin-top: auto;
}
.sidebar {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}
.red {
  color: $red;
  &:hover {
    color: $red !important;
  }
}
.wrapper {
  flex: 1;
  height: 100%;
}
.interaction-menu {
  background: $white;
  padding: 20px 30px;
  box-shadow: 0px 1px 5px 1px rgba(0, 1, 1, 0.1);
  z-index: 999;
  border-radius: 5px;
  width: max-content;
  ::v-deep hr {
    border: 0;
    border-top: 1px solid $grey;
    padding: 5px 0;
  }
  ::v-deep button {
    border: 0;
    padding: 0;
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $text-black;
    cursor: pointer;
    white-space: nowrap;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &:hover {
      color: lighten($text-black, 30%);
    }
  }
}
.ignored-concepts {
  ::v-deep .concept-item {
    filter: grayscale(1);
    opacity: 0.5;
  }
}
.el-tab-pane {
  padding-top: 10px;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  > hr {
    border: 0;
    border-top: 1px solid $grey;
    margin: 20px 20px;
  }
  > label {
    margin: 0 20px 16px;
    display: block;
    > .switch {
      margin-right: 6px;
    }
  }
}
::v-deep {
  .el-tabs__item {
    padding: 0 0 0 26px !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: $text-black;
    box-shadow: none !important;
  }
}
.loading-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tree-wrapper {
  padding: 10px 26px;
  > hr {
    border: 0;
    border-top: 1px solid $grey;
    margin: 20px 0;
  }
}

.create-group {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 2px;
  left: 68px;
  z-index: 1;
  font-size: 12px;

  .icon-wrapper {
    margin-right: 4px;
  }
}

.empty-message {
  color: $text-grey;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
}

.tree-loading {
  text-align: center;
  margin-top: 30px;
}

.content {
  flex: 1;
  position: relative;
  overflow: hidden;
}
</style>
